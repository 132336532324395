import { Tilemaps } from "phaser";
import { AudioManager } from "../AudioManager";

export class Logo extends Phaser.GameObjects.Container {

    constructor(scene: Phaser.Scene) {
        
        super(scene);

        this.y = 250;
        
        AudioManager.play("TitleIntro", false);

        let light = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "rays");
        light.scale = 0;
        this.add(light);

        this.scene.tweens.add({
            targets: light,
            scale: 2.5,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 500,
            delay: 1300,
            onComplete: () => {
                this.scene.tweens.add({
                    targets: light,
                    scale : 1.5,
                    ease: Phaser.Math.Easing.Linear.Linear,
                    duration: 3000,
                    yoyo: true,
                    repeat: -1
                });
            }
        });

        const img1 = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "logo_1");
        img1.setScale(2);
        img1.alpha = 0;
        this.add(img1);

        const img2 = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "logo_2");
        img2.alpha = 0;
        img2.scale = 0.5;
        this.add(img2);

        this.scene.tweens.add({
            targets: img1,
            alpha: 1,
            scaleX: 1,
            scaleY: 1,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 500,
            delay: 500
        });

       this.scene.tweens.add({
            targets: img2,
            scale: 1,
            ease: Phaser.Math.Easing.Elastic.Out,
            duration: 2000,
            delay: 750
        });

        this.scene.tweens.add({
            targets: img2,
            alpha: 1,
            ease: Phaser.Math.Easing.Linear.Linear,
            duration: 500,
            delay: 750
        });

        this.createRay(-203, -30, .75, 1, 1);
        this.createRay(-103, -50, .5, 1.45, -1);
        this.createRay(63, 20, .25, 1.15, -1);
        this.createRay(213, -40, .5, 1.45, 1);
    }

    private createRay(x: number, y: number, initialScale: number, scale: number, rotation: number): Phaser.GameObjects.Image {
        let ray = new Phaser.GameObjects.Image(this.scene, x, y, "texture_atlas_1", "fx_shine");
        ray.scale = initialScale;
        ray.alpha = 0;
        this.add(ray);

        this.scene.tweens.add({
            targets: ray,
            alpha: 1,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 1000,
            delay: 2000,
        });

        this.scene.tweens.add({
            targets: ray,
            scaleX: scale,
            scaleY: scale,
            ease: Phaser.Math.Easing.Linear.Linear,
            duration: 1000,
            yoyo: true,
            repeat: -1
        });

        this.scene.tweens.add({
            targets: ray,
            angle: 360 * rotation,
            ease: Phaser.Math.Easing.Linear.Linear,
            duration: 5000,
            repeat: -1
        });

        return ray;
    }
}
