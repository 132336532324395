import { GameVars } from "./../../../GameVars";
import { GameConstants } from "./../../../GameConstants";
import { BoardContainer } from "./BoardContainer";
import { BoardManager } from "../BoardManager";
import { Chain } from "./Chain";
import { AudioManager } from "../../AudioManager";

export class Block extends Phaser.GameObjects.Container {

    public static bottomBlockMoving: boolean;

    public id: number;
    public value: number;

    public bck: Phaser.GameObjects.Image;
    public frame: Phaser.GameObjects.Image;

    public chainedBlock: Block;
    public chain: Chain;

    public canMove: boolean;

    private movedByChain: boolean;

    private boardContainer: BoardContainer;
    private falling: boolean;
    private velY: number;

    private tweening: boolean;
    private offDownX: number;
    private offDownY: number;

    private onDestroyTween: Phaser.Tweens.Tween;

    constructor(scene: Phaser.Scene, x: number, y: number, id: number, value: number, boardContainer: BoardContainer) {
        
        super(scene);

        this.tweening = false;
        this.falling = false;

        this.x = x;
        this.y = y;

        this.id = id;
        this.value = value;
        this.canMove = false;
        this.movedByChain = false;
        this.velY = 0;

        this.boardContainer = boardContainer;
        this.chainedBlock = null;
        this.chain = null;

        this.bck = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "gem_" + this.value);
        this.bck.setScale(.95);
        this.add(this.bck);

        this.frame = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "golden_frame");
        this.frame.setScale(.95);
        this.frame.visible = this.value >= 20;
        this.add(this.frame);

        this.bck.setInteractive();
        this.bck.on("pointerdown", this.onDown, this);

        this.y += GameConstants.CELL_SIZE;

        this.onDestroyTween = null;

        this.scene.tweens.add({
            targets: this,
            y: this.y - GameConstants.CELL_SIZE,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 250,
            onComplete: () => {
                GameVars.onTween = false;
            },
            onCompleteScope: this
        });
    }

    public update(): void {

        if (!this.scene || this.tweening) {
            return;
        }
        
        if (!this.scene.input.activePointer.isDown) {

            if (this.canMove) {

                this.scale /= 1.1;
                if (this.chainedBlock) {
                    this.chainedBlock.scale /= 1.1;
                }
                AudioManager.play("GemFalls", false);
                this.canMove = false;

                Block.bottomBlockMoving = false;

                let c = Math.round((this.x - BoardContainer.OFF_X) / GameConstants.CELL_SIZE);
                let r = Math.round((this.y - BoardContainer.OFF_Y) / GameConstants.CELL_SIZE);

                this.x = c * GameConstants.CELL_SIZE + BoardContainer.OFF_X;
                this.y = r * GameConstants.CELL_SIZE + BoardContainer.OFF_Y;

                BoardManager.changeBlock(r, c, this.id);

                if (this.chainedBlock) {
                    this.chainedBlock.chainMoveOff();
                }
            }
        }

        if (this.canMove) {

            this.velY = 0;

            let prevX = this.x;
            let prevY = this.y;

            let offX = (this.scene.input.activePointer.x - this.parentContainer.x) / (GameVars.desktop ? .75 : this.boardContainer.boardScale) - prevX - this.offDownX;
            let offY = (this.scene.input.activePointer.y - this.parentContainer.y) / (GameVars.desktop ? .75 : GameVars.scaleY * this.boardContainer.boardScale) - prevY - this.offDownY;

            offX = Phaser.Math.Clamp(offX, -GameConstants.CELL_SIZE_SMALL / 3, GameConstants.CELL_SIZE_SMALL / 3);
            offY = Phaser.Math.Clamp(offY, -GameConstants.CELL_SIZE_SMALL / 3, GameConstants.CELL_SIZE_SMALL / 3);

            let moveTop = true;
            let moveBottom = true;
            let moveLeft = true;
            let moveRight = true;
            
            let collX;
            let collY;

            let blocks = BoardContainer.currentInstance.getBlocks();

            for (let i = 0; i < blocks.length; i++) {
                if (blocks[i].id !== this.id) {
                    if (!this.chainedBlock || (this.chainedBlock && this.chainedBlock.id !== blocks[i].id)) {
                        let r1 = {w: GameConstants.CELL_SIZE_SMALL, h: GameConstants.CELL_SIZE_SMALL, x: this.x, y: this.y};
                        let r2 = {w: GameConstants.CELL_SIZE_SMALL, h: GameConstants.CELL_SIZE_SMALL, x: blocks[i].x, y: blocks[i].y};
                        let collision = this.checkCollsision(r2, r1);
                        if (collision.side !== "none") {

                            if (blocks[i].value !== this.value) {

                                if (collision.value !== 5850) {

                                    if (collision.side === "left") {
                                        collX = blocks[i].x + GameConstants.CELL_SIZE_SMALL;
                                        moveLeft = false;
                                    } else if (collision.side === "right") {
                                        collX = blocks[i].x - GameConstants.CELL_SIZE_SMALL;
                                        moveRight = false;
                                    } else if (collision.side === "top") {
                                        collY = blocks[i].y + GameConstants.CELL_SIZE_SMALL;
                                        moveTop = false;
                                    } else if (collision.side === "bottom") {
                                        collY = blocks[i].y - GameConstants.CELL_SIZE_SMALL;
                                        moveBottom = false;
                                    }
                                }
                            }
                        }
                    }

                    if (this.chainedBlock) {
                        if (blocks[i].id !== this.chainedBlock.id && blocks[i].value !== this.chainedBlock.value) {
                            let r1 = {w: GameConstants.CELL_SIZE_SMALL, h: GameConstants.CELL_SIZE_SMALL, x: this.chainedBlock.x, y: this.chainedBlock.y};
                            let r2 = {w: GameConstants.CELL_SIZE_SMALL, h: GameConstants.CELL_SIZE_SMALL, x: blocks[i].x, y: blocks[i].y};
                            let collision = this.checkCollsision(r2, r1);
                            if (collision.side !== "none") {

                                if (collision.value !== 5850) {

                                    if (collision.side === "left") {
                                        if (this.chainedBlock.x === this.x) {
                                            collX = blocks[i].x + GameConstants.CELL_SIZE_SMALL;
                                            moveLeft = false;
                                        } else if (this.chainedBlock.x < this.x) {
                                            collX = blocks[i].x + GameConstants.CELL_SIZE_SMALL + GameConstants.CELL_SIZE;
                                            moveLeft = false;
                                        }
                                        
                                    } else if (collision.side === "right") {
                                        if (this.chainedBlock.x === this.x) {
                                            collX = blocks[i].x - GameConstants.CELL_SIZE_SMALL;
                                            moveRight = false;
                                        } else if (this.chainedBlock.x > this.x) {
                                            collX = blocks[i].x - GameConstants.CELL_SIZE_SMALL - GameConstants.CELL_SIZE;
                                            moveRight = false;
                                        }
                                        
                                    } else if (collision.side === "top") {
                                        if (this.chainedBlock.y === this.y) {
                                            collY = blocks[i].y + GameConstants.CELL_SIZE_SMALL;
                                            moveTop = false;
                                        } else if (this.chainedBlock.y < this.y) {
                                            collY = blocks[i].y + GameConstants.CELL_SIZE_SMALL + GameConstants.CELL_SIZE;
                                            moveTop = false;
                                        }
                                        
                                    } else if (collision.side === "bottom") {
                                        if (this.chainedBlock.y === this.y) {
                                            collY = blocks[i].y - GameConstants.CELL_SIZE_SMALL;
                                            moveBottom = false;
                                        } else if (this.chainedBlock.y > this.y) {
                                            collY = blocks[i].y - GameConstants.CELL_SIZE_SMALL - GameConstants.CELL_SIZE;
                                            moveBottom = false;
                                        }
                                        
                                    }
                                }
                            }
                        }
                    }
                } 
            }

            if (!moveLeft) {
                this.x = collX;
            }

            if (!moveRight) {
                this.x = collX;
            }

            if (!moveTop) {
                this.y = collY;
            }

            if (!moveBottom) {
                this.y = collY;
            }

            if (moveLeft && offX < 0) {
                this.x += offX;
            }

            if (moveRight && offX > 0) {
                this.x += offX;
            }

            if (moveTop && offY < 0) {
                this.y += offY;
            }

            if (moveBottom && offY > 0) {
                this.y += offY;
            }

            let newC = (this.x + offX - BoardContainer.OFF_X) / GameConstants.CELL_SIZE;
            let newR = (this.y + offY - BoardContainer.OFF_Y) / GameConstants.CELL_SIZE;

            let xBorder = false;
            let yBorder = false;

            // evitar que salga de los bordes
            if (newC < 0) {
                if (moveLeft) {
                    this.x = 0 * GameConstants.CELL_SIZE + BoardContainer.OFF_X;
                    xBorder = true;
                }
            } else if (newC > GameVars.boardDataIds[0].length - 1) {
                if (moveRight) {
                    this.x = (GameVars.boardDataIds[0].length - 1) * GameConstants.CELL_SIZE + BoardContainer.OFF_X;
                    xBorder = true;
                }
            }

            if (newR < 0) {
                if (moveTop) {
                    this.y = 0 * GameConstants.CELL_SIZE + BoardContainer.OFF_Y;
                    yBorder = true;
                }
            } else if (newR > GameVars.boardDataIds.length - 1) {
                if (moveBottom) {
                    this.y = (GameVars.boardDataIds.length - 1) * GameConstants.CELL_SIZE + BoardContainer.OFF_Y;
                    yBorder = true;
                }
            }

            if (this.chainedBlock) {

                newC = (this.chainedBlock.x + offX - BoardContainer.OFF_X) / GameConstants.CELL_SIZE;
                newR = (this.chainedBlock.y + offY - BoardContainer.OFF_Y) / GameConstants.CELL_SIZE;

                // evitar que salga de los bordes
                if (newC < 0) {
                    if (moveLeft && !xBorder) {
                        this.x = 1 * GameConstants.CELL_SIZE + BoardContainer.OFF_X;
                    }
                } else if (newC > GameVars.boardDataIds[0].length - 1) {
                    if (moveRight && !xBorder) {
                        this.x = (GameVars.boardDataIds[0].length - 2) * GameConstants.CELL_SIZE + BoardContainer.OFF_X;
                    }
                }

                if (newR < 0) {
                    if (moveTop && !yBorder) {
                        this.y = 1 * GameConstants.CELL_SIZE + BoardContainer.OFF_Y;
                    }
                } else if (newR > GameVars.boardDataIds.length - 1) {
                    if (moveBottom && !yBorder) {
                        this.y = (GameVars.boardDataIds.length - 2) * GameConstants.CELL_SIZE + BoardContainer.OFF_Y;
                    }
                }
            }

            newC = Math.round((this.x - BoardContainer.OFF_X) / GameConstants.CELL_SIZE);
            newR = Math.round((this.y - BoardContainer.OFF_Y) / GameConstants.CELL_SIZE);

            if (newR === GameVars.boardDataIds.length - 1) {
                Block.bottomBlockMoving = true;
            } else {
                Block.bottomBlockMoving = false;
            }

            BoardManager.changeBlock(newR, newC, this.id);

            if (this.chainedBlock) {
                this.chainedBlock.chainMoveOn(this.x - prevX, this.y - prevY);
            }

            if (this.chain) {
                this.chain.x += this.x - prevX;
                this.chain.y += this.y - prevY;
            }

        } else {

            if (this.movedByChain) {
                return;
            }

            if (this.velY === 0) {
                this.velY = .00001;
            } else {
                this.velY += 1;
            }

            let newY = this.y + this.velY;

            // bajar si el bloque de abajo no esta lleno

            let c = Math.round((this.x - BoardContainer.OFF_X) / GameConstants.CELL_SIZE);
            let r = Math.round((this.y - BoardContainer.OFF_Y - (GameConstants.CELL_SIZE / 2)) / GameConstants.CELL_SIZE);

            let newR = Math.round((newY - BoardContainer.OFF_Y - (GameConstants.CELL_SIZE / 2)) / GameConstants.CELL_SIZE);

            if (this.chainedBlock && this.chainedBlock.y < this.y && this.chain.img.angle === 0) {
                this.y = this.chainedBlock.y;
                this.velY = 0;
            } else if (r !== GameVars.boardDataIds.length - 1 && (GameVars.boardDataIds[r + 1][c] === 0 || GameVars.boardDataIds[r + 1][c] === this.id)) {

                this.y = newY;
                this.boardContainer.bringToTop(this);
                if (this.chain) {
                    this.boardContainer.bringToTop(this.chain);
                }
                this.boardContainer.tutorialTop();
                this.falling = true;
            } else {

                let c = Math.round((this.x - BoardContainer.OFF_X) / GameConstants.CELL_SIZE);
                let r = Math.round((this.y - BoardContainer.OFF_Y) / GameConstants.CELL_SIZE);

                if (GameVars.boardDataIds[r + 1]) {

                    let block = this.boardContainer.getBlock(GameVars.boardDataIds[r + 1][c]);

                    if (block && this.value === block.value) {
                        this.y = newY;
                        this.boardContainer.bringToTop(this);
                        if (this.chain) {
                            this.boardContainer.bringToTop(this.chain);
                        }
                        this.boardContainer.tutorialTop();
                    } else {
                        if (this.falling) {
                            this.falling = false;
                            if (!this.chainedBlock || (this.y < this.chainedBlock.y)) {
                                AudioManager.play("GemHits", false);
                                this.x = c * GameConstants.CELL_SIZE + BoardContainer.OFF_X;
                                this.y = r * GameConstants.CELL_SIZE + BoardContainer.OFF_Y;
                                this.tweening = true;
                                var that = this;
                                this.scene.tweens.add({
                                    targets: this,
                                    y: this.y + 5,
                                    ease: Phaser.Math.Easing.Back.Out,
                                    duration: 100,
                                    
                                    onComplete: function() { 
                                        if (!that.scene) { return; }
                                        that.scene.tweens.add({
                                            targets: that,
                                            y: that.y - 5,
                                            ease: Phaser.Math.Easing.Back.Out,
                                            duration: 300,
                                            
                                            onComplete: function() { 
                                                that.tweening = false;
                                            }
                                        });
                                    }
                                });
                            }
                        }
                        this.velY = 0;

                        this.x = c * GameConstants.CELL_SIZE + BoardContainer.OFF_X;
                        this.y = r * GameConstants.CELL_SIZE + BoardContainer.OFF_Y;
                    }
                } else {
                    this.velY = 0;

                    this.x = c * GameConstants.CELL_SIZE + BoardContainer.OFF_X;
                    this.y = r * GameConstants.CELL_SIZE + BoardContainer.OFF_Y;
                }   
            }

            if (this.chainedBlock) {
                if (this.chainedBlock.y > this.y && this.chain.img.angle === 90) {
                    this.y = this.chainedBlock.y - GameConstants.CELL_SIZE;
                    this.chain.y = this.chainedBlock.y - GameConstants.CELL_SIZE / 2;
                }
            }

            BoardManager.changeBlock(newR, c, this.id);

            // mirar si hay dos bloques muy cerca los juntamos
            this.checkTwoBlocksSamePosition();
        }
        
    }

    public chainMoveOff(): void {

        this.movedByChain = false;

        let c = Math.round((this.x - BoardContainer.OFF_X) / GameConstants.CELL_SIZE);
        let r = Math.round((this.y - BoardContainer.OFF_Y) / GameConstants.CELL_SIZE);

        this.x = c * GameConstants.CELL_SIZE + BoardContainer.OFF_X;
        this.y = r * GameConstants.CELL_SIZE + BoardContainer.OFF_Y;

        BoardManager.changeBlock(r, c, this.id);

    }

    public chainMoveOn(offX: number, offY: number): void {

        this.movedByChain = true;

        this.x += offX;
        this.y += offY; 

        let newC = Math.round((this.x - BoardContainer.OFF_X) / GameConstants.CELL_SIZE);
        let newR = Math.round((this.y - BoardContainer.OFF_Y) / GameConstants.CELL_SIZE);

        BoardManager.changeBlock(newR, newC, this.id);
    }

    public updatePosition(): void {
        let found = false;
        if (!this.canMove && (!this.chainedBlock || (!this.chainedBlock.canMove))) {
            for (let i = 0; i < GameVars.boardDataIds.length; i++) {
                for (let j = 0; j < GameVars.boardDataIds[i].length; j++) {
                    if (GameVars.boardDataIds[i][j] === this.id) {
                        found = true;
                        setTimeout(() => {
                            this.scene.tweens.add({
                                targets: this,
                                x: j * GameConstants.CELL_SIZE + BoardContainer.OFF_X,
                                y: i * GameConstants.CELL_SIZE + BoardContainer.OFF_Y,
                                ease: Phaser.Math.Easing.Cubic.Out,
                                duration: 250,
                                onComplete: () => {
                                    GameVars.onTween = false;
                                },
                                onCompleteScope: this
                            });
                        }, (GameVars.boardDataIds.length - i - 1) * 15);
                    }
                }
            }
        }
        if ((!found && this.onDestroyTween == null && this.y <= -284) || (this.y <= -284 && this.onDestroyTween == null)) {
            this.scene.tweens.killTweensOf(this);
            this.createLoseTweens();
            if (this.chainedBlock != null && this.chainedBlock.onDestroyTween == null) {
                this.scene.tweens.killTweensOf(this.chainedBlock);
                this.chainedBlock.createLoseTweens();
            }
        }
    }

    public onDown(): void {

        if (this.checkTutorialDown()) {
            return;
        }

        this.offDownX = (this.scene.input.activePointer.x - this.parentContainer.x) / (GameVars.desktop ? .75 : this.boardContainer.boardScale) - this.x;
        this.offDownY = (this.scene.input.activePointer.y - this.parentContainer.y) / (GameVars.desktop ? .75 : GameVars.scaleY * this.boardContainer.boardScale) - this.y;

        AudioManager.play("GemClick", false);

        this.canMove = true;
        this.boardContainer.bringToTop(this);
        if (this.chain) {
            this.boardContainer.bringToTop(this.chain);
        }
        this.boardContainer.tutorialTop();

        this.scale *= 1.1;
        if (this.chainedBlock) {
            this.chainedBlock.scale *= 1.1;
        }
    }

    public upgradeBlock(): void {

        AudioManager.play("MergeNumbers", false);

        this.movedByChain = false;
        
        if (this.chainedBlock) {
            this.chainedBlock.chainMoveOff();
        }

        BoardManager.updateScore(this.value);

        this.value++;
        this.bck.setFrame("gem_" + this.value);
        this.frame.visible = this.value >= 20;
        this.boardContainer.addSpark(this.x, this.y, this.value);

        this.scale = .5;
        this.scene.tweens.add({
            targets: this,
            scale: 1,
            ease: Phaser.Math.Easing.Back.Out,
            duration: 100
        });

        BoardManager.checkWin(this.value);
    }

    private createLoseTweens(): void {
        BoardContainer.currentInstance.onLoseGame();
        this.onDestroyTween = this.scene.tweens.add({
            targets: this,
            y: -1 * GameConstants.CELL_SIZE + BoardContainer.OFF_Y,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 250,
        });

        this.scene.tweens.add({
            targets: this,
            scale: 0,
            ease: Phaser.Math.Easing.Back.In,
            duration: 560,
            delay: 200
        });

        setTimeout(() => {
            let img = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "gameover_piece");
            img.alpha = 0;
            this.add(img);

            this.scene.tweens.add({
                targets: img,
                alpha: 1,
                ease: Phaser.Math.Easing.Linear.Linear,
                duration: 300,
            });
    
        }, 250);

        let redDot = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "highlight_gameover_piece");
        redDot.scale = 5;
        redDot.alpha = 0;
        this.add(redDot);

        this.scene.tweens.add({
            targets: redDot,
            alpha: 1,
            scale: 1,
            ease: Phaser.Math.Easing.Linear.Linear,
            duration: 250,
        });

        if (this.chain !== null && !this.chain.toDelete) {
            this.chain.toDelete = true;
            this.scene.tweens.add({
                targets: this.chain,
                scale: 0,
                ease: Phaser.Math.Easing.Back.In,
                duration: 560,
                delay: 200
            });
        } 
    }

    private checkTutorialDown(): boolean {

        if (GameVars.onTutorial) {

            if (GameVars.tutorialPhase === 1) {
                if (this.id !== 1) {
                    return true;
                }
            } else if (GameVars.tutorialPhase === 2) {
                if (this.id !== 6) {
                    return true;
                }
            } else if (GameVars.tutorialPhase === 3) {
                if (this.id !== 5) {
                    return true;
                }
            } else if (GameVars.tutorialPhase === 4) {
                if (this.id !== 3) {
                    return true;
                }
            } else if (GameVars.tutorialPhase === 5) {
                if (this.id !== 2) {
                    return true;
                }
            } else if (GameVars.tutorialPhase === 6) {
                if (this.id !== 6) {
                    return true;
                }
            }
        }

        return false;
    }

    private checkTwoBlocksSamePosition(): void {

        let blocks = BoardContainer.currentInstance.getBlocks();
        
        for (let i = 0; i < blocks.length; i++) {
            if (this.id !== blocks[i].id) {
                // if (this.value === blocks[i].value) {
                    if (this.checkMerge(blocks[i], this)) {

                        if (blocks[i].chainedBlock) {
                            blocks[i].chainedBlock.chainMoveOff();
                        }

                        if (this.chainedBlock) {
                            this.chainedBlock.chainMoveOff();
                        }

                        if (blocks[i].chainedBlock || this.chainedBlock) {
                            AudioManager.play("ChainBreak", false);
                        }

                        if (blocks[i].canMove) {
                            this.upgradeBlock();
                            BoardManager.replaceBlock(blocks[i].id, this.id);
                            blocks[i].destroy();
                            this.boardContainer.removeBlock(blocks[i]);
                            
                        } else {
                            blocks[i].upgradeBlock();
                            BoardManager.replaceBlock(this.id, blocks[i].id);
                            this.destroy();
                            this.boardContainer.removeBlock(this);
                            
                        }
                    }
                // }
            }
        }
    }

    private checkMerge(block1: Block, block2: Block): boolean {
        var x = block1.x - block2.x;
        var y = block1.y - block2.y;

        var dist = Math.sqrt(x * x + y * y);

        return dist < 25;
    }

    private checkCollsision(r1: {w: number, h: number, x: number, y: number}, r2: {w: number, h: number, x: number, y: number}): {side: string, value: number} {

        var dx = (r1.x + r1.w / 2) - (r2.x + r2.w / 2);
        var dy = (r1.y + r1.h / 2) - (r2.y + r2.h / 2);
        var width = (r1.w + r2.w) / 2;
        var height = (r1.h + r2.h) / 2;
        var crossWidth = width * dy;
        var crossHeight = height * dx;
        var collision = {side: "none", value: 0};

        if (Math.abs(dx) <= width && Math.abs(dy) <= height) {
            if (crossWidth > crossHeight) {
                collision = (crossWidth > (-crossHeight)) ? {side: "bottom", value: Math.abs(crossHeight)} : {side: "left", value: Math.abs(crossWidth)};
            } else {
                collision = (crossWidth > -(crossHeight)) ? {side: "right", value: Math.abs(crossWidth)} : {side: "top", value: Math.abs(crossHeight)};
            }
        }
        return collision;
    }
}
