

export class BackgroundParallax {

    public rock: Phaser.GameObjects.Image; 

    private targetY: number;
    private resetY: number;
    private speed: number;
    private currentSpeed: number;
    private stopped: boolean;
    private linear: boolean;
    private reverseLoop: boolean;

    constructor(scene: Phaser.Scene, container: Phaser.GameObjects.Container, sprite: string, initialX: number, initialY: number, targetY: number, resetY: number, speed: number) {
        this.targetY = targetY;
        this.resetY = resetY;
        this.speed = speed;
        this.currentSpeed = speed;
        this.stopped = false;
        this.linear = true;

        this.rock = new Phaser.GameObjects.Image(scene, initialX, initialY, "texture_atlas_1", sprite);
        this.rock.setOrigin(.5);
        this.rock.scale = (initialX > 0) ? 2.1 : -2.1;

        container.add(this.rock);
    }

    public resetSpeed(): void {
        this.currentSpeed = this.speed;
        this.linear = false;
    }

    public stop(): void {
        this.stopped = true;
    }

    public resume(): void {
        this.stopped = false;
    }

    public reverse(): void {

        this.reverseLoop = true;

        let aux = this.resetY;
        this.resetY = this.targetY;
        this.targetY = aux;
    }

    public update(): void {
        if (this.stopped) {
            return;
        }

        if (this.reverseLoop) {
            this.rock.y += this.currentSpeed;
        } else {
            this.rock.y -= this.currentSpeed;
        }

        if ((this.reverseLoop) ? this.rock.y > this.targetY : this.rock.y <= this.targetY) {
            this.rock.y = this.resetY;
        }

        if (!this.linear && !this.reverseLoop) {
            this.currentSpeed -= 0.01;
            if (this.currentSpeed < 0) {
                this.currentSpeed = 0;
            }
        }
    }
}
