import { GameVars } from "../../GameVars";

export class BackgroundMenu extends Phaser.GameObjects.Graphics {

    public width = GameVars.desktop ? 404 : 434;
    public height = GameVars.desktop ? 540 : 575;

    constructor(scene: Phaser.Scene, x: number, y: number) {

        super(scene);

        this.x = x - this.width * .5;
        this.y = y - (GameVars.desktop ? 255 : this.height * .5);

        this.fillStyle(0X1A2835, .7);
        this.fillRect(x, y, this.width, this.height);
    }
}
