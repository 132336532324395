import { AudioManager } from "../AudioManager";
import { GameManager } from "./../../GameManager";
import { GameVars } from "./../../GameVars";

export class StartFromLayer extends Phaser.GameObjects.Container {

    private tick: Phaser.GameObjects.Image;
    private from5: Phaser.GameObjects.Image;
    private from10: Phaser.GameObjects.Image;
    private from15: Phaser.GameObjects.Image;
    private from20: Phaser.GameObjects.Image;
    private from20frame: Phaser.GameObjects.Image;
    private from25: Phaser.GameObjects.Image;
    private from25frame: Phaser.GameObjects.Image;

    constructor(scene: Phaser.Scene, skipIntro?: boolean) {

        super(scene);

        this.x -= 10;

        let background = new Phaser.GameObjects.Image(this.scene, 10, 65, "texture_atlas_1", "box_start_from");
        background.setOrigin(.5);
        this.add(background);

        this.from5 = new Phaser.GameObjects.Image(this.scene, -235, 80, "texture_atlas_1", "gem_5");
        this.from5.scale = 1.25;
        this.from5.alpha = .4;
        this.add(this.from5);

        this.from10 = new Phaser.GameObjects.Image(this.scene, -112.5, 80, "texture_atlas_1", "gem_10");
        this.from10.alpha = .4;
        this.from10.scale = 1.25;
        this.add(this.from10);

        this.from15 = new Phaser.GameObjects.Image(this.scene, 12.5, 80, "texture_atlas_1", "gem_15");
        this.from15.alpha = .4;
        this.from15.scale = 1.25;
        this.add(this.from15);

        this.from20 = new Phaser.GameObjects.Image(this.scene, 135, 80, "texture_atlas_1", "gem_20");
        this.from20.alpha = .4;
        this.from20.scale = 1.25;
        this.add(this.from20);

        this.from20frame = new Phaser.GameObjects.Image(this.scene, 135, 80, "texture_atlas_1", "golden_frame");
        this.from20frame.alpha = .4;
        this.from20frame.scale = 1.25;
        this.add(this.from20frame);

        this.from25 = new Phaser.GameObjects.Image(this.scene, 257.5, 80, "texture_atlas_1", "gem_25");
        this.from25.alpha = .4;
        this.from25.scale = 1.25;
        this.add(this.from25);

        this.from25frame = new Phaser.GameObjects.Image(this.scene, 257.5, 80, "texture_atlas_1", "golden_frame");
        this.from25frame.alpha = .4;
        this.from25frame.scale = 1.25;
        this.add(this.from25frame);

        this.tick = new Phaser.GameObjects.Image(this.scene, this.from5.x, this.from5.y, "texture_atlas_1", "btn_start_selected");
        this.add(this.tick);

        if (GameVars.gameData.bestValue >= 5) {
            this.from5.alpha = 1;
            this.from5.setInteractive({ useHandCursor: true });
            this.from5.on("pointerdown", this.on5Down, this);
        }

        if (GameVars.gameData.bestValue >= 10) {
            this.from10.alpha = 1;
            this.from10.setInteractive({ useHandCursor: true });
            this.from10.on("pointerdown", this.on10Down, this);
        }

        if (GameVars.gameData.bestValue >= 15) {
            this.from15.alpha = 1;
            this.from15.setInteractive({ useHandCursor: true });
            this.from15.on("pointerdown", this.on15Down, this);
        }

        if (GameVars.gameData.bestValue >= 20) {
            this.from20.alpha = 1;
            this.from20frame.alpha = 1;
            this.from20.setInteractive({ useHandCursor: true });
            this.from20.on("pointerdown", this.on20Down, this);
        }

        if (GameVars.gameData.bestValue >= 25) {
            this.from25.alpha = 1;
            this.from25frame.alpha = 1;
            this.from25.setInteractive();
            this.from25.on("pointerdown", this.on25Down, this);
        }

        if (!skipIntro) {
            this.alpha = 0;

            this.scene.tweens.add({
                targets: this,
                alpha: 1,
                ease: Phaser.Math.Easing.Cubic.Out,
                duration: 500,
                delay: 2500
            });
        }

        if (GameVars.startFrom === 5) {
            this.on5Down();
        } else if (GameVars.startFrom === 10) {
            this.on10Down();
        } else if (GameVars.startFrom === 15) {
            this.on15Down();
        } else if (GameVars.startFrom === 20) {
            this.on20Down();
        } else if (GameVars.startFrom === 25) {
            this.on25Down();
        }
    }

    private resetSize(): void {

        this.from5.scale = 1.25;
        this.from10.scale = 1.25;
        this.from15.scale = 1.25;
        this.from20.scale = 1.25;
        this.from20frame.scale = 1.25;
        this.from25.scale = 1.25;
        this.from25frame.scale = 1.25;
    }

    private on5Down(): void {

        AudioManager.play("BtnClick", false);
        this.resetSize();
        GameManager.setStartFrom(5);

        this.tick.setPosition(this.from5.x, this.from5.y);
        this.from5.scale = 1.35;
    }

    private on10Down(): void {
        
        AudioManager.play("BtnClick", false);
        this.resetSize();
        GameManager.setStartFrom(10);

        this.tick.setPosition(this.from10.x, this.from10.y);
        this.from10.scale = 1.35;
    }

    private on15Down(): void {
        
        AudioManager.play("BtnClick", false);
        this.resetSize();
        GameManager.setStartFrom(15);

        this.tick.setPosition(this.from15.x, this.from15.y)
        this.from15.scale = 1.35;
    }

    private on20Down(): void {
        
        AudioManager.play("BtnClick", false);
        this.resetSize();
        GameManager.setStartFrom(20);

        this.tick.setPosition(this.from20.x, this.from20.y);
        this.from20.scale = 1.35;
        this.from20frame.scale = 1.35;
    }

    
    private on25Down(): void {
        
        AudioManager.play("BtnClick", false);
        this.resetSize();
        GameManager.setStartFrom(25);

        this.tick.setPosition(this.from25.x, this.from25.y);
        this.from25.scale = 1.35;
        this.from25frame.scale = 1.35;
    }
}
