import { TutorialContainer } from "./TutorialContainer";
import { Block } from "./Block";
import { GameConstants } from "../../../GameConstants";
import { GameVars } from "../../../GameVars";
import { Chain } from "./Chain";
import { BoardManager } from "../BoardManager";
import { RockParallax } from "../parallax/Parallax";
import { Data, Game } from "phaser";
import { BackgroundParallax } from "../parallax/BackgroundParallax";
import { BoardScene } from "../BoardScene";
import { AudioManager } from "../../AudioManager";

export class BoardContainer extends Phaser.GameObjects.Container {

    public static OFF_X = - (5 / 2) * GameConstants.CELL_SIZE;
    public static OFF_Y = - 294;

    public static currentInstance: BoardContainer;

    public boardScale: number;
    public gameLost: boolean;

    private rocksRight: RockParallax[];
    private rocksLeft: RockParallax[];
    private updateParallax: boolean;
    private timeParallaxStart: number;

    private blocks: Block[];
    private chains: Chain[];
    private tutorialContainer: TutorialContainer;
    private light: RockParallax;

    private forceParallaxUpdate: boolean;
    private rightThirdLevelRocks: BackgroundParallax[];
    private leftThirdLevelRocks: BackgroundParallax[];
    private blockContainer: Phaser.GameObjects.Container;

    constructor(scene: Phaser.Scene) {
        
        super(scene);

        BoardContainer.currentInstance = this;

        this.forceParallaxUpdate = false;

        this.rightThirdLevelRocks = [];
        this.leftThirdLevelRocks = [];
        this.gameLost = false;

        this.x = GameVars.gameWidth / 2;

        if (GameVars.desktop) {
            this.setScale(.75);
            this.y = GameVars.gameHeight / 2 + 85;
        } else {

            if (GameVars.scaleY > 1) {
                this.boardScale = .8;
            } else {
                this.boardScale = 1.2;
            }

            this.setScale(this.boardScale, GameVars.scaleY * this.boardScale);
            this.y = GameVars.gameHeight / 2 + 100 * GameVars.scaleY;
        }

        let background = new Phaser.GameObjects.Image(this.scene, 0, -100, "texture_atlas_1", "background");
        background.setOrigin(.5);
        background.scaleX = 1000;
        background.scaleY = 4;
        this.add(background);

        this.updateParallax = false;
        this.rocksLeft = [];
        this.rocksRight = [];
        
        // Light Parallax
        this.light = new RockParallax(this.scene, this, "glow", 0);
        this.light.toStop = true;
        this.light.rock.setScale(1100, 4);
        this.light.startParallax(-1070, -1170, 200, -1170, 0.75);

        // Right Parallax
        if (GameVars.desktop) {
            const bigRockRight3 = new BackgroundParallax(this.scene, this, "bg_2", 440, 524, -1408, 1168, .25);
            bigRockRight3.rock.alpha = .65;
            bigRockRight3.rock.scale *= .6;
            this.rightThirdLevelRocks.push(bigRockRight3);

            const bigRockRight4 = new BackgroundParallax(this.scene, this, "bg_2", 440, -120, -1408, 1168, .25);
            bigRockRight4.rock.alpha = .65;
            bigRockRight4.rock.scale *= .6;
            this.rightThirdLevelRocks.push(bigRockRight4);

            const bigRockRight5 = new BackgroundParallax(this.scene, this, "bg_2", 440, -764, -1408, 1168, .25);
            bigRockRight5.rock.alpha = .65;
            bigRockRight5.rock.scale *= .6;
            this.rightThirdLevelRocks.push(bigRockRight5);

            const bigRockRight6 = new BackgroundParallax(this.scene, this, "bg_2", 440, 1168, -1408, 1168, .25);
            bigRockRight6.rock.alpha = .65;
            bigRockRight6.rock.scale *= .6;
            this.rightThirdLevelRocks.push(bigRockRight6);

            
            const bigRockRight = new RockParallax(this.scene, this, "bg_2", 550);
            bigRockRight.startParallax(-1170, -120, -120, 950, .8);

    
            const bigRockRight2 = new RockParallax(this.scene, this, "bg_2", 550);
            bigRockRight2.startParallax(-120, -1170, 950, -120, .8);
            

            const smallRockRight = new RockParallax(this.scene, this, "bg_1", 646);
            smallRockRight.startParallax(-1170, -120, -120, 950, 1.25);

            const smallRockRight2 = new RockParallax(this.scene, this, "bg_1", 646);
            smallRockRight2.startParallax(-120, -1170, 950, -120, 1.25);
            

            this.rocksRight.push(smallRockRight);
            this.rocksRight.push(smallRockRight2);
            this.rocksRight.push(bigRockRight);
            this.rocksRight.push(bigRockRight2);

            // Left Parallax
            const bigRockLeft3 = new BackgroundParallax(this.scene, this, "bg_2", -440, 524, -1408, 1168, .25);
            bigRockLeft3.rock.alpha = .65;
            bigRockLeft3.rock.scale *= .6;
            this.leftThirdLevelRocks.push(bigRockLeft3);

            const bigRockLeft4 = new BackgroundParallax(this.scene, this, "bg_2", -440, -120, -1408, 1168, .25);
            bigRockLeft4.rock.alpha = .65;
            bigRockLeft4.rock.scale *= .6;
            this.leftThirdLevelRocks.push(bigRockLeft4);

            const bigRockLeft5 = new BackgroundParallax(this.scene, this, "bg_2", -440, -764, -1408, 1168, .25);
            bigRockLeft5.rock.alpha = .65;
            bigRockLeft5.rock.scale *= .6;
            this.leftThirdLevelRocks.push(bigRockLeft5);

            const bigRockLeft6 = new BackgroundParallax(this.scene, this, "bg_2", -440, 1168, -1408, 1168, .25);
            bigRockLeft6.rock.alpha = .65;
            bigRockLeft6.rock.scale *= .6;
            this.leftThirdLevelRocks.push(bigRockLeft6);

            const bigRockLeft = new RockParallax(this.scene, this, "bg_2", -550);
            bigRockLeft.startParallax(-1170, -120, -120, 950, .8);

            const bigRockLeft2 = new RockParallax(this.scene, this, "bg_2", -550);
            bigRockLeft2.startParallax(-120, -1170, 950, -120, .8);
            
            const smallRockLeft = new RockParallax(this.scene, this, "bg_1", -646);
            smallRockLeft.startParallax(-1170, -120, -120, 950, 1.25);

            const smallRockLeft2 = new RockParallax(this.scene, this, "bg_1", -646);
            smallRockLeft2.startParallax(-120, -1170, 950, -120, 1.25);

            this.rocksLeft.push(smallRockLeft);
            this.rocksLeft.push(smallRockLeft2);
            this.rocksLeft.push(bigRockLeft);
            this.rocksLeft.push(bigRockLeft2);
        } else {
            const bigRockRight3 = new RockParallax(this.scene, this, "bg_2", GameVars.gameWidth - 580);
            bigRockRight3.rock.scaleX = 4 * GameVars.scaleY;
            bigRockRight3.rock.scaleY = 4 * GameVars.scaleY;
            bigRockRight3.startParallax(-1170, -120, -120, 950, 1.5 * GameVars.scaleY);

            const bigRockRight4 = new RockParallax(this.scene, this, "bg_2", GameVars.gameWidth - 580);
            bigRockRight4.rock.scaleX = 4 * GameVars.scaleY;
            bigRockRight4.rock.scaleY = 4 * GameVars.scaleY;
            bigRockRight4.startParallax(-120, -1170, 950, -120, 1.5 * GameVars.scaleY);

            const bigRockLeft5 = new RockParallax(this.scene, this, "bg_2", -190);
            bigRockLeft5.rock.scaleX = -4 * GameVars.scaleY;
            bigRockLeft5.rock.scaleY = -4 * GameVars.scaleY;
            bigRockLeft5.startParallax(-1170, -120, -120, 950, 1.5 * GameVars.scaleY);

            const bigRockLeft4 = new RockParallax(this.scene, this, "bg_2", -190);
            bigRockLeft4.rock.scaleX = -4 * GameVars.scaleY;
            bigRockLeft4.rock.scaleY = -4 * GameVars.scaleY;
            bigRockLeft4.startParallax(-120, -1170, 950, -120, 1.5 * GameVars.scaleY);

            const smallRockLeft = new RockParallax(this.scene, this, "bg_1", -280);
            smallRockLeft.rock.scaleX = -4 * GameVars.scaleY;
            smallRockLeft.rock.scaleY = -4 * GameVars.scaleY;
            smallRockLeft.startParallax(-1170, -120, -120, 950, 2.25 * GameVars.scaleY);

            const smallRockLeft2 = new RockParallax(this.scene, this, "bg_1", -280);
            smallRockLeft2.rock.scaleX = -4 * GameVars.scaleY;
            smallRockLeft2.rock.scaleY = -4 * GameVars.scaleY;
            smallRockLeft2.startParallax(-120, -1170, 950, -120, 2.25 * GameVars.scaleY);

            const smallRockRight = new RockParallax(this.scene, this, "bg_1", GameVars.gameWidth - 500);
            smallRockRight.rock.scaleX = 4 * GameVars.scaleY;
            smallRockRight.rock.scaleY = 4 * GameVars.scaleY;
            smallRockRight.startParallax(-1170, -120, -120, 950, 2.25 * GameVars.scaleY);

            const smallRockRight2 = new RockParallax(this.scene, this, "bg_1", GameVars.gameWidth - 500);
            smallRockRight2.rock.scaleX = 4 * GameVars.scaleY;
            smallRockRight2.rock.scaleY = 4 * GameVars.scaleY;
            smallRockRight2.startParallax(-120, -1170, 950, -120, 2.25 * GameVars.scaleY);

            this.rocksLeft.push(smallRockLeft);
            this.rocksLeft.push(smallRockLeft2);
            this.rocksLeft.push(bigRockLeft4);
            this.rocksLeft.push(bigRockLeft5);

            this.rocksRight.push(smallRockRight);
            this.rocksRight.push(smallRockRight2);
            this.rocksRight.push(bigRockRight4);
            this.rocksRight.push(bigRockRight3);
        }

        this.blockContainer = new Phaser.GameObjects.Container(this.scene);
        if (!GameVars.desktop) {
            if (GameVars.aspectRatio < 1.5) {
                this.blockContainer.scale = GameVars.aspectRatio * .55 * GameVars.scaleY;
            }
        }
        this.add(this.blockContainer);

        let grid = new Phaser.GameObjects.Image(this.scene, .5, 0, "texture_atlas_1", "frame");
        this.blockContainer.add(grid);

        this.blocks = [];
        this.chains = [];

        let count = 0;
        let nums = [];

        if (GameVars.onTutorial) {
            nums = [4, 2, 5, 2, 6, 3];
        } else {
            // rellenamos todo de numeros menores al maximo
            for (let i = 0; i < 12; i++) {
                nums.push(Math.floor(Math.random() * (GameVars.startFrom - 1)) + 1);
            }

            // comprobamos que no se junten entre si
            for (let i = 0; i < 6; i++) {

                while (nums[i] === nums[i + 6]) {
                    nums[i] = Math.floor(Math.random() * (GameVars.startFrom - 1)) + 1;
                }
            }

            // añadimos el num maximo en una posicion random
            nums[Math.floor(Math.random() * 12)] = GameVars.startFrom;
        }

        GameVars.maxValue = GameVars.startFrom;

        for (let i = 0; i < GameVars.boardDataIds.length; i++) {
            for (let j = 0; j < GameVars.boardDataIds[i].length; j++) {

                if (GameVars.boardDataIds[i][j] !== 0) {
                    let block = new Block(this.scene, 
                        BoardContainer.OFF_X + j * GameConstants.CELL_SIZE, 
                        BoardContainer.OFF_Y + i * GameConstants.CELL_SIZE, 
                        GameVars.boardDataIds[i][j],
                        nums[count],
                        this);
                    this.blockContainer.add(block);
                    this.blocks.push(block);

                    count++;
                }
            }
        }

        if (GameVars.startFrom >= 10) {
            BoardManager.addNewChains();
        }

        if (GameVars.onTutorial) {
            this.tutorialContainer = new TutorialContainer(this.scene);
            this.blockContainer.add(this.tutorialContainer);
        }

        this.initAnimations();
    }

    public update(): void {

        if (!GameVars.paused) {
            for (let i = 0; i < this.chains.length; i++) {
                this.chains[i].update();
            }
        }

        if (!GameVars.paused || this.forceParallaxUpdate) {
            if (this.updateParallax || this.forceParallaxUpdate) {
                if (this.forceParallaxUpdate || new Date().getTime() - this.timeParallaxStart < 2000) {
                    for (let i = 0; i < this.rocksRight.length; i++) {
                        this.rocksRight[i].update();
                    }
                    for (let i = 0; i < this.rocksLeft.length; i++) {
                        this.rocksLeft[i].update();
                    }
                    for (let i = 0; i < this.rightThirdLevelRocks.length; ++i) {
                        this.rightThirdLevelRocks[i].update();
                    }
                    for (let i = 0; i < this.leftThirdLevelRocks.length; ++i) {
                        this.leftThirdLevelRocks[i].update();
                    }
                    this.light.update();
                } else {
                    this.updateParallax = false;
                }
            }
        }

        if (this.gameLost || GameVars.paused) {
            return;
        }

        for (let i = 0; i < this.blocks.length; i++) {
            if (!GameVars.onTween || this.blocks[i].canMove) {
                this.blocks[i].update();
            }
        }
    }

    public getBlocks(): Block[] {

        return this.blocks;
    }

    public getBlock(id: number): Block {

        for (let i = 0; i < this.blocks.length; i++) {
            if (id === this.blocks[i].id) {
                return this.blocks[i];
            }
        }
    }

    public onLoseGame(): void {
        
        if (!this.gameLost) {
            this.gameLost = true;
            BoardScene.currentInstance.gui.hideMenu();

            setTimeout(() => {
                GameVars.paused = true;
                BoardScene.currentInstance.showGameOver();
            }, 1000);
        }
    }

    public removeBlock(block: Block): void {

        let index = this.blocks.indexOf(block);

        if (index !== -1) {
            this.blocks.splice(index, 1);
        }
    }

    public removeChain(id1: number, id2: number): void {

        for (let i = 0; i < this.chains.length; i++) {
            if (id1 === this.chains[i].blocks[0].id || id1 === this.chains[i].blocks[1].id) {

                this.chains[i].blocks[0].chainedBlock = null;
                this.chains[i].blocks[1].chainedBlock = null;

                this.getBlock(id1).chain = null;
                this.getBlock(id2).chain = null;

                let chain = this.chains[i];
                this.chains.splice(i, 1);
                chain.destroy();

                i--;
            } else if (id2 === this.chains[i].blocks[0].id || id2 === this.chains[i].blocks[1].id) {

                this.chains[i].blocks[0].chainedBlock = null;
                this.chains[i].blocks[1].chainedBlock = null;

                this.getBlock(id1).chain = null;
                this.getBlock(id2).chain = null;

                let chain = this.chains[i];
                this.chains.splice(i, 1);
                chain.destroy();

                i--;
            }
        }
    }

    public addNewBlocks(): void {
        
        let max = GameVars.maxValue - 2;
        let array = [];
        for (let i = 1; i <= max; i++) {
            array.push(i);
        }

        for (let j = 0; j < GameVars.boardDataIds[0].length; j++) {
            let i = GameVars.boardDataIds.length - 1;
            let block = new Block(this.scene, 
                BoardContainer.OFF_X + j * GameConstants.CELL_SIZE, 
                BoardContainer.OFF_Y + i * GameConstants.CELL_SIZE, 
                GameVars.boardDataIds[i][j],
                this.generateValidValue(i, j, array),
                this);
            this.blockContainer.add(block);
            this.blocks.push(block);
        }

        this.reStartParallax();

        AudioManager.play("GemsUp", false);
    }

    public reStartParallax(): void {
        this.updateParallax = true;
        this.timeParallaxStart = new Date().getTime();

        this.light.resetSpeed();

        this.rocksRight[0].resetSpeed();
        this.rocksRight[1].resetSpeed();

        for (let i = 2; i < this.rocksRight.length; ++i) {
            this.rocksRight[i].stop();
        }
        for (let i = 0; i < this.rightThirdLevelRocks.length; ++i) {
            this.rightThirdLevelRocks[i].stop();
        }

        setTimeout(() => {
            this.rocksRight[2].resume();
            this.rocksRight[2].resetSpeed();

            this.rocksRight[3].resume();
            this.rocksRight[3].resetSpeed();
        }, 200);

        this.rocksLeft[0].resetSpeed();
        this.rocksLeft[1].resetSpeed();

        for (let i = 2; i < this.rocksLeft.length; ++i) {
            this.rocksLeft[i].stop();
        }
        for (let i = 0; i < this.leftThirdLevelRocks.length; ++i) {
            this.leftThirdLevelRocks[i].stop();
        }

        setTimeout(() => {
            this.rocksLeft[2].resume();
            this.rocksLeft[2].resetSpeed();

            this.rocksLeft[3].resume();
            this.rocksLeft[3].resetSpeed();
        }, 200);

        if (GameVars.desktop) {
            setTimeout(() => {
                for (let i = 0; i < this.rightThirdLevelRocks.length; ++i) {
                    this.rightThirdLevelRocks[i].resume();
                    this.rightThirdLevelRocks[i].resetSpeed();
                }

                for (let i = 0; i < this.leftThirdLevelRocks.length; ++i) {
                    this.leftThirdLevelRocks[i].resume();
                    this.leftThirdLevelRocks[i].resetSpeed();
                }
            }, 500);
        }
    }

    public makeRocksGoUp(): void {
        this.reStartParallax();
        for (let i = 0; i < this.rocksRight.length; i++) {
            this.rocksRight[i].reverse();
        }
        for (let i = 0; i < this.rocksLeft.length; i++) {
            this.rocksLeft[i].reverse();
        }
        for (let i = 0; i < this.rightThirdLevelRocks.length; ++i) {
            this.rightThirdLevelRocks[i].reverse();
        }
        for (let i = 0; i < this.leftThirdLevelRocks.length; ++i) {
            this.leftThirdLevelRocks[i].reverse();
        }
        this.light.reverse();
        this.forceParallaxUpdate = true;
    }

    public generateValidValue(i: number, j: number, array: number[]): number {

        let value = array[Math.floor(Math.random() * array.length)];

        if (GameVars.boardDataIds[i - 1][j] !== 0) {
            while (this.getBlock(GameVars.boardDataIds[i - 1][j]) && value === this.getBlock(GameVars.boardDataIds[i - 1][j]).value) {
                value = array[Math.floor(Math.random() * array.length)];
            }
        }

        return value;
    }

    public addNewChain(id1: number, id2: number): void {

        let blocks = [];

        blocks.push(this.getBlock(id1));
        blocks.push(this.getBlock(id2));

        let chain = new Chain(this.scene, blocks);
        this.blockContainer.add(chain);
        this.chains.push(chain);

        this.getBlock(id1).chainedBlock = this.getBlock(id2);
        this.getBlock(id2).chainedBlock = this.getBlock(id1);

        this.getBlock(id1).chain = chain;
        this.getBlock(id2).chain = chain;
    }

    public updateBlockPositions(): void {

        for (let i = 0; i < this.blocks.length; i++) {

            this.blocks[i].updatePosition();
        }
    }

    public addSpark(x: number, y: number, value: number): void {

        let sparkDown = this.scene.add.sprite(x, y + 40, "texture_atlas_1");
        this.blockContainer.add(sparkDown);

        sparkDown.anims.play("sparks_down");

        sparkDown.on("animationcomplete", () => {
            sparkDown.destroy();
        }, this);

        let sparkRadial = this.scene.add.sprite(x, y, "texture_atlas_1");
        sparkRadial.tint = GameConstants.SPARKS_COLOURS[value % 10 - 1];
        this.blockContainer.add(sparkRadial);

        sparkRadial.anims.play("sparks_radial");

        sparkRadial.on("animationcomplete", () => {
            sparkRadial.destroy();
        }, this);
    }

    public startTutorialPhaseTwo(): void {

        this.tutorialContainer.startPhaseTwo();
    }

    public startTutorialPhaseThree(): void {

        this.tutorialContainer.startPhaseThree();
    }

    public startTutorialPhaseFour(): void {

        this.tutorialContainer.startPhaseFour();
    }

    public startTutorialPhaseFive(): void {

        this.tutorialContainer.startPhaseFive();
    }

    public startTutorialPhaseSix(): void {

        this.tutorialContainer.startPhaseSix();
    }

    public showEndTutorial(): void {

        this.tutorialContainer.destroy();
    }

    public tutorialTop(): void {

        if (this.tutorialContainer) {
            this.bringToTop(this.tutorialContainer);
        }
    }

    private initAnimations(): void {

        this.scene.anims.create({ key: "sparks_down", frames: this.scene.anims.generateFrameNames( "texture_atlas_1", { prefix: "sparks_down_", start: 1, end: 19, zeroPad: 2, suffix: ""}), frameRate: 30});
        this.scene.anims.create({ key: "sparks_radial", frames: this.scene.anims.generateFrameNames( "texture_atlas_1", { prefix: "sparks_radial_", start: 1, end: 23, zeroPad: 2, suffix: ""}), frameRate: 30});
    }
}
