import { Block } from "./Block";

export class Chain extends Phaser.GameObjects.Container {

    public id: number;
    public blocks: Block[];
    public img: Phaser.GameObjects.Image;
    public toDelete: boolean;

    constructor(scene: Phaser.Scene, blocks: Block[]) {

        super(scene);

        this.blocks = blocks;

        this.img = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "chain_cell");
        this.add(this.img);

        if (this.blocks[0].x - this.blocks[1].x === 0) {
            this.img.angle = 90;
        }

        this.toDelete = false;

        this.alpha = 0;

        this.scene.tweens.add({
            targets: this,
            alpha: 1,
            ease: Phaser.Math.Easing.Linear.Linear,
            duration: 250
        });

    }

    public update(): void {

        let x = (this.blocks[0].x + this.blocks[1].x) / 2;
        let y = (this.blocks[0].y + this.blocks[1].y) / 2;

        this.setPosition(x, y);
    }
}
