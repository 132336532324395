import { AudioManager } from "../scenes/AudioManager";

export class ButtonEx extends Phaser.GameObjects.Container {

    private defaultScale: { x: number, y: number };
    private background: Phaser.GameObjects.Image;
    private backgroundHover: Phaser.GameObjects.Image;
    private button: Phaser.GameObjects.Image;

    constructor(scene: Phaser.Scene, x: number, y: number, textureAtlas: string, frameNameOff: string, pixelPerfect?: boolean) {

        super(scene, x, y);

        this.background = new Phaser.GameObjects.Image(scene, 0, 0, "texture_atlas_1", "btn_hover");
        this.background.scale = .8;
        this.add(this.background);

        this.backgroundHover = new Phaser.GameObjects.Image(scene, 0, 0, "texture_atlas_1", "btn_base");
        this.backgroundHover.scale = .8;
        this.backgroundHover.visible = false;
        this.add(this.backgroundHover);

        this.button = new Phaser.GameObjects.Image(scene, 0, 0, textureAtlas, frameNameOff);

        if (pixelPerfect) {
            this.button.setInteractive(this.scene.input.makePixelPerfect(20));
            this.button.setInteractive({ pixelPerfect: true, useHandCursor: true });
        } else {
            this.button.setInteractive({ useHandCursor: true });
        }

        this.button.on("pointerover", () => {
            this.backgroundHover.visible = true;
        }, this);

        this.button.on("pointerout", () => {
            this.backgroundHover.visible = false;
        }, this);
        this.button.on("pointerdown", () => {
            AudioManager.play("BtnClick", false);
        }, this);
        this.add(this.button);
    }

    public setFrame(frame: string) {
        this.button.setFrame(frame);
    }

    public onUp(f: Function, context: any): void {
        this.button.on("pointerup", f, context);
    }

    public onDown(f: Function, context: any): void {
        this.button.on("pointerdown", f, context);
    }

    public onOver(f: Function, context: any): void {
        this.button.on("pointerover", f, context);
    }

    public onOut(f: Function, context: any): void {
        this.button.on("pointerout", f, context);
    }
}

