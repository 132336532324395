import { GameConstants } from "./GameConstants";
import { GameVars } from "./GameVars";
import { AudioManager } from "./scenes/AudioManager";
const { SimplioUserInfoSDK } = require('@SimplioOfficial/simplio-user-info-sdk');

export class GameManager {

    public static init(): void {

        const urlParams = new URLSearchParams(window.location.search);

        GameVars.simplioApiUrl = urlParams.get("env") || "test";
        GameVars.simplioUserId = urlParams.get("userId") || "test";
        GameVars.simplioGameId = "X2oW40w7lCZp-rNQCyNxTWnB1122e7Jvl53cWncROBS3AzFuxGGQpg==";

        GameVars.simplio = new SimplioUserInfoSDK(GameVars.simplioApiUrl, GameVars.simplioGameId);

        GameVars.intitialised = true;
        GameVars.startFrom = 5;

        if (GameVars.desktop) {

            GameVars.scaleY = 1;
            GameVars.aspectRatio = 1;
            
        } else {

            GameVars.currentScene.game.scale.displaySize = GameVars.currentScene.game.scale.parentSize;
            GameVars.currentScene.game.scale.refresh();

            GameVars.aspectRatio = window.innerHeight / window.innerWidth;
            GameVars.scaleY = (GameVars.gameHeight / GameVars.gameWidth) / GameVars.aspectRatio;
        }

        GameManager.readGameData();
    }

    public static setStartFrom(value: number): void {

        GameVars.startFrom = value;
    }

    public static readGameData(): void {

        GameManager.getGameStorageData(
            GameConstants.SAVED_GAME_DATA_KEY,
            function (gameData: string): void {

                if (gameData) {
                    GameVars.gameData = JSON.parse(gameData);
                } else {
                    GameVars.gameData = {
                        muted: false,
                        highscore: 0,
                        bestValue: 0,
                        startTutorial: false
                    };
                }

                GameManager.startGame();
            }
        );
    }

    public static restoreGameData(): void {

        GameVars.gameData = {
            muted: false,
            highscore: 0,
            bestValue: 0,
            startTutorial: true
        };

        GameManager.writeGameData();
    }

    public static setCurrentScene(scene: Phaser.Scene): void {

        GameVars.currentScene = scene;
    }

    public static onGameAssetsLoaded(): void {

        if (GameVars.gameData.startTutorial) {
            GameManager.enterBoardScene(true);
            AudioManager.play("BackgroundMusic", true);
        } else {
            // GameManager.enterSplashScene();
            GameManager.enterBoardScene(false);
        }
    }

    public static enterSplashScene(): void {

        GameVars.currentScene.scene.start("SplashScene");
    }

    public static enterLevelSelectionScene(): void {

        GameVars.currentScene.scene.start("LevelSelectionScene");
    }

    public static enterBoardScene(tutorial: boolean): void {

        GameVars.onTutorial = tutorial;

        GameVars.currentScene.scene.start("BoardScene");
    }

    public static writeGameData(): void {

        GameManager.setGameStorageData(
            GameConstants.SAVED_GAME_DATA_KEY,
            GameVars.gameData,
            function (): void {
                GameManager.log("game data successfully saved");
            }
        );
    }

    public static log(text: string, error?: Error): void {

        if (!GameConstants.VERBOSE) {
            return;
        }

        if (error) {
            console.error(text + ":", error);
        } else {
            console.log(text);
        }
    }

    private static startGame(): void {

        GameVars.currentScene.scene.start("PreloadScene");
    }

    private static getGameStorageData(key: string, successCb: Function): void {

        const gameDataStr = localStorage.getItem(key);
        successCb(gameDataStr);
    }

    private static setGameStorageData(key: string, value: any, successCb: Function): void {

        localStorage.setItem(key, JSON.stringify(value));
        successCb();
    }
}
