export class GameVars {

    public static scaleY: number;
    public static aspectRatio: number;
    public static gameData: GameData;
    public static currentScene: Phaser.Scene;
    public static paused: boolean;
    public static intitialised: boolean;
    public static boardDataIds: number[][];
    public static chains: {blocks: number[]}[];
    public static gameWidth: number;
    public static gameHeight: number;
    public static timer: number;
    public static waitTimer: number;
    public static comboTimer: number;
    public static comboScore: number;
    public static combo: number;
    public static score: number;
    public static startFrom: number;
    public static maxValue: number;
    public static onTutorial: boolean;
    public static tutorialPhase: number;
    public static onTween: boolean;
    public static desktop: boolean;

    public static simplioApiUrl: string;
    public static simplioUserId: string;
    public static simplioGameId: string;
    public static simplio: any;
    
    public static formatNumber(value: number): string {

        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    public static getColorName(id: number): string {

        let ret: string;

        switch (id) {
            case 1:
                ret = "green";
                break;
            case 2:
                ret = "red";
                break;
            case 3:
                ret = "yellow";
                break;
            case 4:
                ret = "blue";
                break;
            case 5:
                ret = "cyan";
                break;
            case 6:
                ret = "grey";
                break;
            case 7:
                ret = "pink";
                break;
            case 8:
                ret = "orange";
                break;
            case 9:
                ret = "purple";
                break;
    
            default:
                ret = "none";
                break;
        }

        return ret;
    }

    public static areValidDomains(domains: string[]): boolean {

        let isValid = false;

        for (let i = 0; i < domains.length; i++) {
            if (window.location.hostname.indexOf(domains[i]) !== -1) {
                isValid = true;
                break;
            }
        }

        return isValid;
    }
}
