import { GameConstants } from "../../GameConstants";
import { GameVars } from "../../GameVars";
import { BackgroundMenu } from "./BackgroundMenu";
import { Button } from "../../utils/Utils";
import { GameManager } from "../../GameManager";
import { ButtonEx } from "../../utils/ButtonEx";

export class WinLayer extends Phaser.GameObjects.Container {

    constructor(scene: Phaser.Scene) {
        
        super(scene);

        this.visible = false;

        const midContainer = new Phaser.GameObjects.Container(this.scene, GameVars.gameWidth / 2, GameVars.gameHeight / 2 + (GameVars.desktop ? 40 : 60 * GameVars.scaleY));
        if (!GameVars.desktop) {
            midContainer.scaleY = GameVars.scaleY * 1.5;
            midContainer.scaleX = 1.5;
        }
        this.add(midContainer);

        const midBck = new BackgroundMenu(this.scene, 0, 15);
        midContainer.add(midBck);

        const title = new Phaser.GameObjects.Image(this.scene, 0, -160, "texture_atlas_1", "txt_win");
        title.scale = .75;
        midContainer.add(title);

        let rays = new Phaser.GameObjects.Image(this.scene, 10, 20, "texture_atlas_1", "rays");
        rays.scale = 1.5;
        midContainer.add(rays);

        let man = new Phaser.GameObjects.Image(this.scene, 0, 30, "texture_atlas_1", "char");
        man.scale = .65;
        midContainer.add(man);

        let block = new Phaser.GameObjects.Image(this.scene, 0, 30, "texture_atlas_1", "char_finger");
        block.scale = .65;
        midContainer.add(block);

        const restartBtn = new ButtonEx(this.scene, -100, 230, "texture_atlas_1", "btn_icon_replay");
        restartBtn.scale = .75;
        restartBtn.onDown(this.onRestartDown, this);
        midContainer.add(restartBtn);

        const restartText = new Phaser.GameObjects.Text(this.scene, -100, 280, "RESTART", {fontFamily: "Supercell", fontSize: "25px", color: "#ffe00c"});
        restartText.setOrigin(.5);
        midContainer.add(restartText);

        let restartBck = new Phaser.GameObjects.Graphics(this.scene);
        restartBck.setPosition(-120, 280);
        restartBck.fillStyle(0xffffff, .0);
        restartBck.fillRect(-68, -25, 175, 60);
        restartBck.setInteractive(new Phaser.Geom.Rectangle(-68, -25, 175, 60), Phaser.Geom.Rectangle.Contains);
        restartBck.on("pointerdown", this.onRestartDown, this);
        midContainer.add(restartBck);

        // const homeBtn = new ButtonEx(this.scene, 100, 230, "texture_atlas_1", "btn_home");
        // homeBtn.scale = .75;
        // homeBtn.onDown(this.onHomeDown, this);
        // midContainer.add(homeBtn);

        // const homeText = new Phaser.GameObjects.Text(this.scene, 100, 280, "HOME", {fontFamily: "Supercell", fontSize: "25px", color: "#00FFC1"});
        // homeText.setOrigin(.5);
        // midContainer.add(homeText);

        // let homeBck = new Phaser.GameObjects.Graphics(this.scene);
        // homeBck.setPosition(90, 280);
        // homeBck.fillStyle(0xffffff, .0);
        // homeBck.fillRect(-58, -25, 145, 60);
        // homeBck.setInteractive(new Phaser.Geom.Rectangle(-58, -25, 145, 60), Phaser.Geom.Rectangle.Contains);
        // homeBck.on("pointerdown", this.onRestartDown, this);
        // midContainer.add(homeBck);
    }

    public show(): void {

        this.alpha = 0;
        this.visible = true;

        this.scene.tweens.add({
            targets: this,
            alpha: 1,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 500
        });
    }

    private onRestartDown(): void {

        GameManager.enterBoardScene(false); 
    }

    private onHomeDown(): void {

        GameManager.enterSplashScene(); 
    }
}
