import { GameVars } from "../../GameVars";
import { BackgroundMenu } from "./BackgroundMenu";
import { GameManager } from "../../GameManager";
import { ButtonEx } from "../../utils/ButtonEx";
import { BoardContainer } from "./board-container/BoardContainer";
import { AudioManager } from "../AudioManager";

export class GameOverLayer extends Phaser.GameObjects.Container {

    constructor(scene: Phaser.Scene) {
        
        super(scene);

        BoardContainer.currentInstance.makeRocksGoUp();

        AudioManager.stop();
        setTimeout(() => {
            AudioManager.play("BackgroundMusic", true);
            AudioManager.setVolume(0.3);
        }, 3500);

        AudioManager.play("GameOver", false);

        let midContainer = new Phaser.GameObjects.Container(this.scene, GameVars.gameWidth / 2, GameVars.gameHeight / 2 + (GameVars.desktop ? 70 : 100 * GameVars.scaleY));
        if (!GameVars.desktop) {
            midContainer.scaleY = GameVars.scaleY * 1.5;
            midContainer.scaleX = 1.5;
        }
        this.add(midContainer);

        let midBck = new BackgroundMenu(this.scene, 0, 0);
        midBck.alpha = 0;
        midContainer.add(midBck);

        this.scene.tweens.add({
            targets: midBck,
            alpha: 1,
            ease: Phaser.Math.Easing.Linear.Linear,
            duration: 150
        });

        let title = new Phaser.GameObjects.Image(this.scene, 0, 180, "texture_atlas_1", "txt_game-over");
        title.scale = .75;
        title.angle = -10;
        title.alpha = 0;
        midContainer.add(title);

        this.scene.tweens.add({
            targets: title,
            angle: 0,
            ease: Phaser.Math.Easing.Elastic.Out,
            duration: 3000,
            delay: 300,
        });

        this.scene.tweens.add({
            targets: title,
            alpha: 1,
            ease: Phaser.Math.Easing.Back.Out,
            duration: 1000,
            delay: 300,
        });

        let face = new Phaser.GameObjects.Image(this.scene, 0, -20, "texture_atlas_1", "char_loser");
        face.scale = 0;
        midContainer.add(face);

        this.scene.tweens.add({
            targets: face,
            scale: .75,
            ease: Phaser.Math.Easing.Sine.Out,
            duration: 400,
            delay: 800,
            onComplete: () => {
                AudioManager.play("GameOverCry", false);
                this.scene.tweens.add({
                    targets: face,
                    scale: .65,
                    ease: Phaser.Math.Easing.Cubic.Out,
                    duration: 2000,
                    yoyo: true,
                    repeat: -1
                });
            }
        });

        let restartBtn = new ButtonEx(this.scene, -100, 130, "texture_atlas_1", "btn_icon_replay");
        restartBtn.scale = .75;
        restartBtn.onDown(this.onRestartDown, this);
        midContainer.add(restartBtn);

        let restartText = new Phaser.GameObjects.Text(this.scene, -50, 110, "RESTART", {fontFamily: "Supercell", fontSize: "25px", color: "#ffe00c"});
        midContainer.add(restartText);

        let restartBck = new Phaser.GameObjects.Graphics(this.scene);
        restartBck.setPosition(0, 127);
        restartBck.fillStyle(0xffffff, .0);
        restartBck.fillRect(-68, -35, 195, 70);
        restartBck.setInteractive(new Phaser.Geom.Rectangle(-68, -35, 195, 70), Phaser.Geom.Rectangle.Contains);
        restartBck.on("pointerdown", this.onRestartDown, this);
        midContainer.add(restartBck);

        restartBtn.alpha = 0;
        restartText.alpha = 0;
        this.scene.tweens.add({
            targets: [restartText, restartBtn],
            alpha: 1,
            ease: Phaser.Math.Easing.Linear.Linear,
            duration: 250,
            delay: 1800,
            onComplete: () => {
                this.scene.tweens.add({
                    targets: restartBtn,
                    scaleX: 1.015,
                    scaleY: 1.015,
                    ease: Phaser.Math.Easing.Cubic.Out,
                    duration: 400,
                    yoyo: true,
                    repeat: -1
                });
            }
        });

        // let homeBtn = new ButtonEx(this.scene, -100, 210, "texture_atlas_1", "btn_home");
        // homeBtn.scale = .75;
        // homeBtn.onDown(this.onHomeDown, this);
        // midContainer.add(homeBtn);

        // let homeText = new Phaser.GameObjects.Text(this.scene, -50, 190, "HOME", {fontFamily: "Supercell", fontSize: "25px", color: "#00FFC1"});
        // midContainer.add(homeText);

        // let homeBck = new Phaser.GameObjects.Graphics(this.scene);
        // homeBck.setPosition(0, 207);
        // homeBck.fillStyle(0xffffff, .0);
        // homeBck.fillRect(-68, -35, 195, 70);
        // homeBck.setInteractive(new Phaser.Geom.Rectangle(-68, -35, 195, 70), Phaser.Geom.Rectangle.Contains);
        // homeBck.on("pointerdown", this.onHomeDown, this);
        // midContainer.add(homeBck);

        // homeBtn.alpha = 0;
        // homeText.alpha = 0;
        // this.scene.tweens.add({
        //     targets: [homeText, homeBtn],
        //     alpha: 1,
        //     ease: Phaser.Math.Easing.Linear.Linear,
        //     duration: 250,
        //     delay: 2000
        // });
    }

    private onRestartDown(): void {

        GameManager.enterBoardScene(false); 
    }

    private onHomeDown(): void {

        GameManager.enterSplashScene(); 
    }
}
