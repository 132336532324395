import { GameManager } from "../GameManager";
import { GameVars } from "../GameVars";

// audiosprite --e "mp3,ogg" --o ../../assets/audio/sound *.mp3 --f howler -b 96
// audiosprite --e "mp3,ogg" --o ../../assets/audio/music *.mp3 --f howler -b 96
export class AudioManager {

    private static muted: boolean;

    public static init(scene: Phaser.Scene): void {
        scene.load.audio("BackgroundMusic", "assets/audio/soundtrack_merge_gems.mp3");
        scene.load.audio("ChainBreak", "assets/audio/chain_break.mp3");
        scene.load.audio("BtnClick", "assets/audio/btn_click.mp3");
        scene.load.audio("MergeNumbers", "assets/audio/numbers_merge.mp3");
        scene.load.audio("GameOver", "assets/audio/game_over.mp3");
        scene.load.audio("GameOverCry", "assets/audio/game_over_cry.mp3");
        scene.load.audio("GemsUp", "assets/audio/gems_up.mp3");
        scene.load.audio("TitleIntro", "assets/audio/title_intro.mp3");
        scene.load.audio("GemClick", "assets/audio/gem_click.mp3");
        scene.load.audio("GemFalls", "assets/audio/gem_falls.mp3");
        scene.load.audio("GemHits", "assets/audio/gem_hits.mp3");

        AudioManager.muted = GameVars.gameData.muted;
        GameVars.currentScene.sound.mute = AudioManager.muted;
    }

    public static play(audio: string, loop: boolean): void {
        GameVars.currentScene.sound.play(audio, {loop: loop});
    }

    public static stop(): void {
        GameVars.currentScene.sound.stopAll();
    }

    public static setVolume(volume: number): void {
        GameVars.currentScene.sound.volume = volume;
    }

    public static isMuted(): boolean {
        return AudioManager.muted;
    }

    public static changeMute(): void {
        AudioManager.mute(!AudioManager.muted);
    }

    public static mute(mute: boolean): void {
        AudioManager.muted = mute;
        GameVars.gameData.muted = mute;
        GameManager.writeGameData();
        GameVars.currentScene.sound.mute = mute;
    }
}
