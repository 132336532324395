

export class RockParallax {

    public rock: Phaser.GameObjects.Image;   
    public toStop: boolean;

    private speed: number;
    private targetY: number;
    private nextTargetY: number;
    private toSetY: number;
    private nextToSetY: number;
    private currentSpeed: number;
    private stopped: boolean;
    private linear: boolean;
    private reverseLoop: boolean;

    constructor(scene: Phaser.Scene, container: Phaser.GameObjects.Container, sprite: string, posX: number) {
        this.rock = new Phaser.GameObjects.Image(scene, posX, 0, "texture_atlas_1", sprite);
        this.rock.setOrigin(.5);
        this.rock.scale = (posX > 0) ? 2.1 : -2.1;
        this.toStop = false;
        this.stopped = false;
        this.linear = false;
        container.add(this.rock);
    }

    public startParallax(targetY: number, nextTargetY: number, toSetY: number, nextToSetY: number, speed: number): void {
        this.targetY = targetY;
        this.nextTargetY = nextTargetY;
        this.toSetY = toSetY;
        this.nextToSetY = nextToSetY;
        this.speed = speed;
        this.currentSpeed = speed;

        this.rock.y = toSetY;
    }

    public resetSpeed(): void {
        this.currentSpeed = this.speed;
    }

    public stop(): void {
        this.stopped = true;
    }

    public resume(): void {
        this.stopped = false;
    }

    public setLinearSpeed(linear: boolean): void {
        this.linear = linear;
    }

    public reverse(): void {
        this.reverseLoop = true;
        
        let auxtargetY = this.targetY;
        let auxNextTargetY = this.nextTargetY;
        let toSetY = this.toSetY;
        let nextToSetY = this.nextToSetY;

        this.targetY = toSetY;
        this.nextTargetY = nextToSetY;
        this.toSetY = auxtargetY;
        this.nextToSetY = auxNextTargetY;
    }

    public update(): void {
        if (this.stopped) {
            return;
        }

        if (this.reverseLoop) {
            this.rock.y += this.currentSpeed;
        } else {
            this.rock.y -= this.currentSpeed;
        }

        if ((this.reverseLoop) ? this.rock.y > this.targetY : this.rock.y <= this.targetY) {
            if (this.toStop) {
                this.stopped = true;
                return;
            }
            let auxTarget = this.targetY;
            let auxSetY = this.toSetY;

            this.rock.y = this.nextToSetY + this.speed;

            this.targetY = this.nextTargetY;
            this.nextTargetY = auxTarget;

            this.toSetY = this.nextToSetY;
            this.nextToSetY = auxSetY;
        }
        
        if (!this.linear && !this.reverseLoop) {
            this.currentSpeed -= 0.01;
            if (this.currentSpeed < 0) {
                this.currentSpeed = 0;
            }
        }
    }
}
