import { GameManager } from "./../../GameManager";
import { GameVars } from "../../GameVars";
import { BoardManager } from "./BoardManager";
import { BackgroundMenu } from "./BackgroundMenu";
import { ButtonEx } from "../../utils/ButtonEx";
import { AudioManager } from "../AudioManager";

export class MenuLayer extends Phaser.GameObjects.Container {

    private audioButton: ButtonEx;

    constructor(scene: Phaser.Scene) {
        
        super(scene);

        this.visible = false;

        const backBtn = new ButtonEx(this.scene, GameVars.desktop ? 55 : 70, GameVars.desktop ? 55 : 70 * GameVars.scaleY, "texture_atlas_1", "btn_back");
        backBtn.onUp(this.onClickBack, this);
        backBtn.scaleY = GameVars.scaleY;
        this.add(backBtn);

        let midContainer = new Phaser.GameObjects.Container(this.scene, GameVars.gameWidth / 2, GameVars.gameHeight / 2 + (GameVars.desktop ? 70 : 100 * GameVars.scaleY));
        if (!GameVars.desktop) {
            midContainer.scaleY = GameVars.scaleY * 1.5;
            midContainer.scaleX = 1.5;
        }
        this.add(midContainer);

        let midBck = new BackgroundMenu(this.scene, 0, 0);
        midContainer.add(midBck);

        let title = new Phaser.GameObjects.Image(this.scene, 0, -170, "texture_atlas_1", "txt_pause");
        title.scale = .75;
        midContainer.add(title);

        let continueBtn = new ButtonEx(this.scene, -100, -40, "texture_atlas_1", "btn_icon_continue");
        continueBtn.scale = .75;
        continueBtn.onDown(this.onClickBack, this);
        midContainer.add(continueBtn);

        let continueText = new Phaser.GameObjects.Text(this.scene, -50, -60, "CONTINUE", {fontFamily: "Supercell", fontSize: "25px", color: "#ffe00c"});
        midContainer.add(continueText);

        let continueBck = new Phaser.GameObjects.Graphics(this.scene);
        continueBck.setPosition(0, -43);
        continueBck.fillStyle(0xffffff, .0);
        continueBck.fillRect(-68, -35, 195, 70);
        continueBck.setInteractive(new Phaser.Geom.Rectangle(-68, -35, 195, 70), Phaser.Geom.Rectangle.Contains);
        continueBck.on("pointerdown", this.onClickBack, this);
        midContainer.add(continueBck);

        let restartBtn = new ButtonEx(this.scene, -100, 40, "texture_atlas_1", "btn_icon_replay");
        restartBtn.scale = .75;
        restartBtn.onDown(this.onRestartDown, this);
        midContainer.add(restartBtn);

        let restartText = new Phaser.GameObjects.Text(this.scene, -50, 20, "RESTART", {fontFamily: "Supercell", fontSize: "25px", color: "#ffe00c"});
        midContainer.add(restartText);

        let restartBck = new Phaser.GameObjects.Graphics(this.scene);
        restartBck.setPosition(0, 37);
        restartBck.fillStyle(0xffffff, .0);
        restartBck.fillRect(-68, -35, 195, 70);
        restartBck.setInteractive(new Phaser.Geom.Rectangle(-68, -35, 195, 70), Phaser.Geom.Rectangle.Contains);
        restartBck.on("pointerdown", this.onRestartDown, this);
        midContainer.add(restartBck);

        this.audioButton = new ButtonEx(this.scene, -100, 120, "texture_atlas_1", AudioManager.isMuted() ? "btn_icon_sound_off" : "btn_icon_sound_on");
        this.audioButton.scale = .75;
        this.audioButton.onDown(this.onClickAudio, this);
        midContainer.add(this.audioButton);

        let audioText = new Phaser.GameObjects.Text(this.scene, -50, 100, "SOUND", {fontFamily: "Supercell", fontSize: "25px", color: "#00FFC1"});
        midContainer.add(audioText);

        let audioBck = new Phaser.GameObjects.Graphics(this.scene);
        audioBck.setPosition(0, 117);
        audioBck.fillStyle(0xffffff, .0);
        audioBck.fillRect(-68, -35, 195, 70);
        audioBck.setInteractive(new Phaser.Geom.Rectangle(-68, -35, 195, 70), Phaser.Geom.Rectangle.Contains);
        audioBck.on("pointerdown", this.onClickAudio, this);
        midContainer.add(audioBck);

        // let homeBtn = new ButtonEx(this.scene, -100, 200, "texture_atlas_1", "btn_home");
        // homeBtn.scale = .75;
        // homeBtn.onDown(this.onHomeDown, this);
        // midContainer.add(homeBtn);

        // let homeText = new Phaser.GameObjects.Text(this.scene, -50, 180, "HOME", {fontFamily: "Supercell", fontSize: "25px", color: "#00FFC1"});
        // midContainer.add(homeText);

        // let homeBck = new Phaser.GameObjects.Graphics(this.scene);
        // homeBck.setPosition(0, 197);
        // homeBck.fillStyle(0xffffff, .0);
        // homeBck.fillRect(-68, -35, 195, 70);
        // homeBck.setInteractive(new Phaser.Geom.Rectangle(-68, -35, 195, 70), Phaser.Geom.Rectangle.Contains);
        // homeBck.on("pointerdown", this.onHomeDown, this);
        // midContainer.add(homeBck);
    }

    private onRestartDown(): void {

        if (GameVars.startFrom === 1 && !GameVars.onTutorial) {
            GameManager.setStartFrom(5);
        }

        GameManager.enterBoardScene(GameVars.onTutorial); 
    }

    private onHomeDown(): void {

        BoardManager.tutorialEnd();

        GameManager.enterSplashScene(); 
    }

    private onClickBack(): void {

        BoardManager.hideMenu();
    }

    private onClickAudio(): void {
        AudioManager.changeMute();
        if (!AudioManager.isMuted()) {
            this.audioButton.setFrame("btn_icon_sound_on");
        } else {
            this.audioButton.setFrame("btn_icon_sound_off");
        }
    }
}
