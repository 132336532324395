import { BoardScene } from "./BoardScene";
import { GameManager } from "./../../GameManager";
import { BoardContainer } from "./board-container/BoardContainer";
import { GameConstants } from "./../../GameConstants";
import { GameVars } from "./../../GameVars";
import { Block } from "./board-container/Block";

export class BoardManager {

    public static init(): void {
        
        GameVars.paused = false;
        GameVars.timer = 0;
        GameVars.waitTimer = 0;
        GameVars.score = 0;
        GameVars.maxValue = 1;
        GameVars.tutorialPhase = 1;
        GameVars.onTween = true;
        GameVars.combo = 0;
        GameVars.comboTimer = 0;
        GameVars.comboScore = 0;

        GameVars.boardDataIds = [
            [0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0],
            [1, 2, 3, 4, 5, 6],
            [7, 8, 9, 10, 11, 12]
        ];

        if (GameVars.onTutorial) {
            GameVars.boardDataIds = [
                [0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0, 6],
                [1, 2, 3, 0, 4, 5]
            ];
        }

        GameVars.chains = [];
    }

    public static update(): void {

        if (GameVars.onTween || BoardContainer.currentInstance.gameLost) {
            return;
        }

        BoardManager.checkPosibleMerge();

        if (GameVars.timer >= GameConstants.MAX_TIME + (GameVars.maxValue * 10)) {

            GameVars.waitTimer++;

            if (GameVars.waitTimer === 5) {
                GameVars.timer = 0;
                GameVars.waitTimer = 0;
                BoardManager.addNewBlocks();
            }

        } else {
            if (!GameVars.onTutorial) {
                GameVars.timer ++;
            }
        }

        if (GameVars.combo > 0) {

            if (GameVars.comboTimer === 30) {

                if (GameVars.comboScore > 0) {
                    GameVars.score += (GameVars.comboScore * GameVars.combo);
                    GameVars.comboScore = 0;

                    if (GameVars.score > GameVars.gameData.highscore) {
                        GameVars.gameData.highscore = GameVars.score;
                        GameManager.writeGameData();
                    }
                }

                GameVars.combo = 0;
                GameVars.comboTimer = 0;
                BoardScene.currentInstance.hideCombo();
                
            } else {
                GameVars.comboTimer++;
            }
        }
    }

    public static checkPosibleMerge(): void {

        let blocks = BoardContainer.currentInstance.getBlocks();
        let values = blocks.map(a => a.value);
        
        // no hay mas posibles merge, se adelanta el timer

        if (values.length === new Set(values).size) {
            GameVars.timer = GameConstants.MAX_TIME + (GameVars.maxValue * 10);
        }
    }

    public static addNewBlocks(): void {

        let lastId = 0;

        for (let i = 0; i < GameVars.boardDataIds.length; i++) {
            for (let j = 0; j < GameVars.boardDataIds[i].length; j++) {
                if (i !== 0) {
                    lastId = Math.max(lastId, GameVars.boardDataIds[i][j]);
                    GameVars.boardDataIds[i - 1][j] = GameVars.boardDataIds[i][j];
                    GameVars.boardDataIds[i][j] = 0;
                }
            }
        }

        for (let j = 0; j < GameVars.boardDataIds[0].length; j++) {
            lastId++;
            GameVars.boardDataIds[GameVars.boardDataIds.length - 1][j] = lastId;
        }

        GameVars.onTween = true;

        BoardContainer.currentInstance.updateBlockPositions();
        BoardContainer.currentInstance.addNewBlocks();

        if (!Block.bottomBlockMoving) {
            BoardManager.addNewChains();
        }
        
    }

    public static addNewChains(): void {

        let numChains = 0;

        if (GameVars.maxValue >= 20) {
            numChains = 3;
        } else if (GameVars.maxValue >= 15) {
            numChains = 2;
        } else if (GameVars.maxValue >= 10) {
            numChains = 1;
        }

        let oldPos = -1;

        for (let i = 0; i < numChains; i++) {

            let pos = Math.floor(Math.random() * 6);

            while (pos === oldPos) {
                pos = Math.floor(Math.random() * 6);
            }

            let dir = Math.floor(Math.random() * 3);

            while (
                (dir === 1 && GameVars.boardDataIds[GameVars.boardDataIds.length - 2][pos] === 0) || 
                (pos === 5 && dir === 2) || 
                (pos === 0 && dir === 0)) {
                    dir = Math.floor(Math.random() * 3);
            }
    
            let id1 = GameVars.boardDataIds[GameVars.boardDataIds.length - 1][pos];
            let id2;
    
            if (dir === 0) {
                id2 = GameVars.boardDataIds[GameVars.boardDataIds.length - 1][pos - 1];
            } else if (dir === 1) {
                id2 = GameVars.boardDataIds[GameVars.boardDataIds.length - 2][pos];
            } else {
                id2 = GameVars.boardDataIds[GameVars.boardDataIds.length - 1][pos + 1];
            }

            if (!BoardManager.chainInBlock(id1) && !BoardManager.chainInBlock(id2)) {
                GameVars.chains.push({blocks: [id1, id2]});
                BoardContainer.currentInstance.addNewChain(id1, id2);

                oldPos = pos;
            }
        }
    }

    public static chainInBlock(id: number): boolean {

        for (let i = 0; i < GameVars.chains.length; i++) {
            if (GameVars.chains[i].blocks[0] === id || GameVars.chains[i].blocks[1] === id) {
                return true;
            }
        }

        return false;
    }

    public static changeBlock(r: number, c: number, id: number): void {

        if (GameVars.boardDataIds[r] === undefined) {
            return;
        }

        BoardManager.removeBlock(id);

        GameVars.boardDataIds[r][c] = id;

        if (GameVars.onTutorial && GameVars.tutorialPhase === 2) {
            if (id === 6 && r === 7 && c === 3) {
                GameVars.tutorialPhase = 3;
                BoardContainer.currentInstance.startTutorialPhaseThree();
            }
        }
    }

    public static removeBlock(id: number): void {

        for (let i = 0; i < GameVars.boardDataIds.length; i++) {
            for (let j = 0; j < GameVars.boardDataIds[i].length; j++) {
                if (GameVars.boardDataIds[i][j] === id) {
                    GameVars.boardDataIds[i][j] = 0;
                }
            }
        }
    }

    public static replaceBlock(oldId: number, newId: number): void {

        if (GameVars.onTutorial) {
            BoardManager.checkreplaceTutorial();
        }

        BoardContainer.currentInstance.removeChain(oldId, newId);

        BoardManager.removeBlock(newId);

        for (let i = 0; i < GameVars.boardDataIds.length; i++) {
            for (let j = 0; j < GameVars.boardDataIds[i].length; j++) {
                if (GameVars.boardDataIds[i][j] === oldId) {
                    GameVars.boardDataIds[i][j] = newId;
                }
            }
        }
    }

    public static checkreplaceTutorial(): void {

        if (GameVars.tutorialPhase === 1) {
            GameVars.tutorialPhase = 2;
            BoardContainer.currentInstance.startTutorialPhaseTwo();
        } else if (GameVars.tutorialPhase === 3) {
            GameVars.tutorialPhase = 4;
            BoardContainer.currentInstance.startTutorialPhaseFour();
        } else if (GameVars.tutorialPhase === 4) {
            GameVars.tutorialPhase = 5;
            BoardContainer.currentInstance.startTutorialPhaseFive();
        } else if (GameVars.tutorialPhase === 5) {
            GameVars.tutorialPhase = 6;
            BoardContainer.currentInstance.startTutorialPhaseSix();
        } else if (GameVars.tutorialPhase === 6) {
            GameVars.tutorialPhase = 7;
            BoardManager.showEndTutorial();
        }
    }

    public static showEndTutorial(): void {

        BoardScene.currentInstance.showEndTutorial();
    }

    public static tutorialEnd(): void {

        BoardScene.currentInstance.tutorialEnd();

        GameVars.gameData.startTutorial = false;
        GameVars.onTutorial = false;
        GameManager.writeGameData();
    }

    public static updateScore(value: number): void {

        GameVars.combo++;
        GameVars.comboTimer = 0;

        if (GameVars.combo > 0) {
            if (GameVars.combo > 1) {
                BoardScene.currentInstance.updateCombo();
            }
            GameVars.comboScore += value;
        } else {
            GameVars.score += value;
        }

        if (GameVars.score > GameVars.gameData.highscore) {
            GameVars.gameData.highscore = GameVars.score;
            GameManager.writeGameData();
        }
    }

    public static onGameOver(): void {

        GameVars.paused = true;
        BoardScene.currentInstance.showGameOver();
    }

    public static checkWin(value: number): void {

        if (value > GameVars.maxValue) {
            GameVars.maxValue = value;
        }

        if (value > GameVars.gameData.bestValue) {
            GameVars.gameData.bestValue = value;
            GameManager.writeGameData();
        }

        if (value === GameConstants.MAX_NUMBER) {
            BoardManager.onWin();
        }
    }

    public static onWin(): void {

        GameVars.paused = true;
        BoardScene.currentInstance.showWin();
    }

    public static showMenu(): void {

        GameVars.paused = true;
        BoardScene.currentInstance.showMenu();
    }

    public static hideMenu(): void {

        GameVars.paused = false;
        BoardScene.currentInstance.hideMenu();
    }
}
