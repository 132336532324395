import { GameManager } from "../GameManager";
import { GameVars } from "../GameVars";
import { AudioManager } from "./AudioManager";

export class PreloadScene extends Phaser.Scene {

    public static currentInstance: PreloadScene;

    private background_1: Phaser.GameObjects.Graphics;

    constructor() {

        super("PreloadScene");

        PreloadScene.currentInstance = this;
    }

    public preload(): void {

        this.composeScene();

        this.loadAssets();
    }

    public create(): void {

        GameManager.onGameAssetsLoaded();
    }
    
    private composeScene(): void {

        this.add.text(60, -42, "Continue", {fontFamily: "Supercell", fontSize: "45px", color: "#00FFC1"});

        this.background_1 = this.add.graphics();
        this.background_1.fillStyle(0x061F2E);
        this.background_1.fillRect(0, 0, GameVars.gameWidth, GameVars.gameHeight);
    }

    private loadAssets(): void {

        this.load.atlas("texture_atlas_1", "assets/texture_atlas_1.png", "assets/texture_atlas_1.json");
        
        AudioManager.init(this);
    }
}
