import { StartFromLayer } from "./StartFromLayer";
import { GameVars } from "../../GameVars";
import { GameConstants } from "../../GameConstants";
import { GameManager } from "../../GameManager";
import { BootScene } from "../BootScene";
import { Logo } from "./Logo";
import { ButtonEx } from "../../utils/ButtonEx";
import { RockParallax } from "../board-scene/parallax/Parallax";
import { AudioManager } from "../AudioManager";
import { Game } from "phaser";
import { BackgroundParallax } from "../board-scene/parallax/BackgroundParallax";
import { Button } from "../../utils/Utils";

export class SplashScene extends Phaser.Scene {

    public static currentInstance: SplashScene;

    private logo: Logo;
    private rocks: BackgroundParallax[];
    private audioButton: ButtonEx;
    
    constructor() {

        super("SplashScene");
        
        SplashScene.currentInstance = this;
    }

    public create(): void {

        GameManager.setCurrentScene(this);

        AudioManager.stop();
        AudioManager.setVolume(1);

        setTimeout(() => {
            AudioManager.play("BackgroundMusic", true);
            AudioManager.setVolume(1);
        }, 3500);

        let background = new Phaser.GameObjects.Image(this, 0, 370, "texture_atlas_1", "background");
        background.setOrigin(.5);
        background.scaleX = 1000;
        background.scaleY = 3;
        this.add.existing(background);

        this.rocks = [];

        let light = new Phaser.GameObjects.Image(this, 400, GameVars.desktop ? 700 : 1000, "texture_atlas_1", "glow");
        light.scaleX = 1200;
        this.add.existing(light);

        let container = this.add.container(0, 0);

        if (GameVars.desktop) {
            let leftNormalRock0 = new BackgroundParallax(this, container, "bg_2", 250, 3345, -955, 3345, .25);
            leftNormalRock0.rock.alpha = 0.65;
            leftNormalRock0.rock.scaleX *= -0.6;
            this.rocks.push(leftNormalRock0);

            let leftNormalRock1 = new BackgroundParallax(this, container, "bg_2", 250, 120, -955, 3345, .25);
            leftNormalRock1.rock.alpha = 0.65;
            leftNormalRock1.rock.scaleX *= -0.6;
            this.rocks.push(leftNormalRock1);

            let leftNormalRock2 = new BackgroundParallax(this, container, "bg_2", 250, 1195, -955, 3345, .25);
            leftNormalRock2.rock.alpha = 0.65;
            leftNormalRock2.rock.scaleX *= -0.6;
            this.rocks.push(leftNormalRock2);

            let leftNormalRock3 = new BackgroundParallax(this, container, "bg_2", 250, 2270, -955, 3345, .25);
            leftNormalRock3.rock.alpha = 0.65;
            leftNormalRock3.rock.scaleX *= -0.6;
            this.rocks.push(leftNormalRock3);

            let rightNormalRock0 = new BackgroundParallax(this, container, "bg_2", 780, 3345, -955, 3345, .25);
            rightNormalRock0.rock.alpha = 0.65;
            rightNormalRock0.rock.scaleX *= 0.6;
            this.rocks.push(rightNormalRock0);

            let rightNormalRock1 = new BackgroundParallax(this, container, "bg_2", 780, 120, -955, 3345, .25);
            rightNormalRock1.rock.alpha = 0.65;
            rightNormalRock1.rock.scaleX *= 0.6;
            this.rocks.push(rightNormalRock1);

            let rightNormalRock2 = new BackgroundParallax(this, container, "bg_2", 780, 1195, -955, 3345, .25);
            rightNormalRock2.rock.alpha = 0.65;
            rightNormalRock2.rock.scaleX *= 0.6;
            this.rocks.push(rightNormalRock2);

            let rightNormalRock3 = new BackgroundParallax(this, container, "bg_2", 780, 2270, -955, 3345, .25);
            rightNormalRock3.rock.alpha = 0.65;
            rightNormalRock3.rock.scaleX *= 0.6;
            this.rocks.push(rightNormalRock3);
        }

        let leftRock1 = new BackgroundParallax(this, container, "bg_2", 130, 120, -600, 1550, .5);
        leftRock1.rock.scaleX = -leftRock1.rock.scaleX;
        this.rocks.push(leftRock1);

        let leftRock2 = new BackgroundParallax(this, container, "bg_2", 130, 1195, -600, 1550, .5);
        leftRock2.rock.scaleX = -leftRock2.rock.scaleX;
        this.rocks.push(leftRock2);

        let leftSmallRock1 = new BackgroundParallax(this, container, "bg_1", 25, 120, -600, 1550, .85);
        leftSmallRock1.rock.scaleX = -leftSmallRock1.rock.scaleX;
        this.rocks.push(leftSmallRock1);

        let leftSmallRock2 = new BackgroundParallax(this, container, "bg_1", 25, 1195, -600, 1550, .85);
        leftSmallRock2.rock.scaleX = -leftSmallRock2.rock.scaleX;
        this.rocks.push(leftSmallRock2);

        let rightRock1 = new BackgroundParallax(this, container, "bg_2", GameVars.gameWidth - 130, 120, -600, 1550, .5);
        this.rocks.push(rightRock1);

        let rightRock2 = new BackgroundParallax(this, container, "bg_2", GameVars.gameWidth - 130, 1195, -600, 1550, .5);
        this.rocks.push(rightRock2);

        let leftBigRock1 = new BackgroundParallax(this, container, "bg_1", GameVars.gameWidth - 25, 120, -600, 1550, .85);
        this.rocks.push(leftBigRock1);

        let leftBigRock2 = new BackgroundParallax(this, container, "bg_1", GameVars.gameWidth - 25, 1195, -600, 1550, .85);
        this.rocks.push(leftBigRock2);

        const tutorialBtn = new ButtonEx(this, GameVars.gameWidth - 55, 55, "texture_atlas_1", "btn_tutorial");
        tutorialBtn.alpha = 0;
        tutorialBtn.scaleY = GameVars.scaleY;
        tutorialBtn.onDown(this.onClickTutorial, this);
        this.add.existing(tutorialBtn);

        const topContainer = new Phaser.GameObjects.Container(this, GameVars.gameWidth / 2, GameVars.desktop ? -80 : 0);
        topContainer.scaleY = GameVars.scaleY;
        this.add.existing(topContainer);

        this.logo = new Logo(this);
        topContainer.add(this.logo);

        const bottomContainer = new Phaser.GameObjects.Container(this, GameVars.gameWidth / 2, GameVars.desktop ? GameVars.gameHeight - 360 : GameVars.gameHeight - 450);
        bottomContainer.scaleY = GameVars.scaleY;
        this.add.existing(bottomContainer);

        const startFromLayer = new StartFromLayer(this);
        if (GameVars.desktop) {
            startFromLayer.scale = .55;
            startFromLayer.y = 275;
        } else if (GameVars.aspectRatio < 1.5) {
            startFromLayer.scale = 0.5 * GameVars.aspectRatio;
            startFromLayer.y = 170 * GameVars.aspectRatio;
        } else {
            startFromLayer.y = 250;
        }
        bottomContainer.add(startFromLayer);

        let man = new Phaser.GameObjects.Image(this, 0, -35, "texture_atlas_1", "char");
        if (GameVars.desktop) {
            man.scale = .75;
            man.y = 75;
        } else if (GameVars.aspectRatio < 1.5) {
            man.scale = 0.5 * GameVars.aspectRatio;
            man.y = 30 * GameVars.aspectRatio;
        }
        man.alpha = 0;
        bottomContainer.add(man);

        let ray = new Phaser.GameObjects.Image(this, 70, -85, "texture_atlas_1", "fx_shine");
        if (!GameVars.desktop) {
            if (GameVars.aspectRatio >= 1.5) {
                ray.setPosition(95, -230);
            }
        }
        ray.scale = .8;
        ray.alpha = 0;
        bottomContainer.add(ray);

        this.tweens.add({
            targets: ray,
            scale: 1.3,
            ease: Phaser.Math.Easing.Linear.Linear,
            duration: 1000,
            yoyo: true,
            repeat: -1
        });

        this.tweens.add({
            targets: ray,
            angle: 360,
            ease: Phaser.Math.Easing.Linear.Linear,
            duration: 5000,
            repeat: -1
        });

        const playButton = new ButtonEx(this, GameVars.desktop ? 160 : 190, GameVars.desktop ? 185 : 120, "texture_atlas_1", "btn_play");
        playButton.onUp(this.onClickPlay, this);
        bottomContainer.add(playButton);
        playButton.alpha = 0;

        this.tweens.add({
            targets: playButton,
            alpha: 1,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 500,
            delay: 2500
        });

        this.audioButton = new ButtonEx(this, GameVars.gameWidth - 55, GameVars.gameHeight - 50, "texture_atlas_1", AudioManager.isMuted() ? "btn_icon_sound_off" : "btn_icon_sound_on");
        this.audioButton.onDown(this.onClickAudio, this);
        this.audioButton.scaleY = GameVars.scaleY;
        this.add.existing(this.audioButton);
        this.audioButton.alpha = 0;

        this.tweens.add({
            targets: this.audioButton,
            alpha: 1,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 500,
            delay: 2500
        });

        this.tweens.add({
            targets: [man, ray],
            alpha: 1,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 500,
            delay: 2500
        });


        this.tweens.add({
            targets: tutorialBtn,
            alpha: 1,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 500,
            delay: 2500
        });

        this.tweens.add({
            targets: playButton,
            scaleX: 1.065,
            scaleY: 1.065,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 400,
            yoyo: true,
            repeat: -1
        });

        if (!GameVars.desktop) {
            this.input.on("pointerdown", () => { 

                if (!this.game.scale.isFullscreen) {
                    this.game.scale.startFullscreen();
                    BootScene.onOrientationChange();
                }
            }, this);
        }
    }

    public update(): void {
        for (let i = 0; i < this.rocks.length; ++i) {
            this.rocks[i].update();
        }
    }

    public onClickPlay(): void {

        this.cameras.main.fadeOut(300, 0, 0, 0);

        this.cameras.main.once("camerafadeoutcomplete", function (): void {
            GameManager.enterBoardScene(false);
        });
    }

    public onClickTutorial(): void {

        GameManager.setStartFrom(1);

        if (!GameVars.desktop) {
            if (!this.game.scale.isFullscreen) {
                this.game.scale.startFullscreen();
                BootScene.onOrientationChange();
            }
        }

        this.cameras.main.fadeOut(300, 0, 0, 0);

        this.cameras.main.once("camerafadeoutcomplete", function (): void {
            GameManager.enterBoardScene(true);
        });
    }

    public onClickAudio(): void {
        AudioManager.changeMute();
        if (!AudioManager.isMuted()) {
            this.audioButton.setFrame("btn_icon_sound_on");
        } else {
            this.audioButton.setFrame("btn_icon_sound_off");
        }
    }
}
