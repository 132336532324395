import { GameManager } from "../GameManager"; 
import { Game } from "../Game";
import { GameVars } from "../GameVars";

export class BootScene extends Phaser.Scene {

    public static currentInstance: BootScene;

    public static onOrientationChange(): void {

        BootScene.currentInstance.time.addEvent({ delay: 200, callback: () => {
            
            GameVars.currentScene.game.scale.displaySize = GameVars.currentScene.game.scale.parentSize;
            Game.currentInstance.scale.refresh();
            
            GameVars.scaleY = (GameVars.gameHeight / GameVars.gameWidth) / (window.innerHeight / window.innerWidth);
            
        }, callbackScope: BootScene.currentInstance});
    }
    
    constructor() {

        super("BootScene");
    }

    public preload(): void {

        this.createGradients();

        this.load.image("bg", "assets/bg.png");
    }

    public create(): void {

        this.add.text(-100, -100, "abcdefg", { fontFamily: "TitanOne", fontSize: 28, color: "#A6F834" });
        this.add.text(-100, -100, "abcdefg", { fontFamily: "Baloo", fontSize: 28, color: "#A6F834" });

        BootScene.currentInstance = this;
        GameManager.setCurrentScene(this);

        this.scene.setVisible(false);

        if (GameVars.desktop) {
            GameManager.init();
        } else {

            if (window.innerWidth > window.innerHeight) {
                document.getElementById("orientation").style.display = "block";
                document.getElementById("content").style.display = "none";
            } else {
                GameManager.init();
            }
        }
    }

    private createGradients(): void {

        var texture = this.textures.createCanvas("gradient_bck", 16, 256);
        var context = texture.getContext();
        var grd = context.createLinearGradient(0, 0, 0, 256);

        grd.addColorStop(0, "#1f2e3c");
        grd.addColorStop(1, "#1f2e3c");

        context.fillStyle = grd;
        context.fillRect(0, 0, 16, 256);

        texture.refresh();

        texture = this.textures.createCanvas("gradient_header", 16, 256);
        context = texture.getContext();
        grd = context.createLinearGradient(0, 0, 0, 256);

        grd.addColorStop(0, "#C6D6DD");
        grd.addColorStop(1, "#A4B5BD");

        context.fillStyle = grd;
        context.fillRect(0, 0, 16, 256);

        texture.refresh();

        texture = this.textures.createCanvas("gradient_preload", 16, 256);
        context = texture.getContext();
        grd = context.createLinearGradient(0, 0, 0, 256);

        grd.addColorStop(0, "#67DECC");
        grd.addColorStop(1, "#0FB0CC");

        context.fillStyle = grd;
        context.fillRect(0, 0, 16, 256);

        texture.refresh();
    }
}
