import { GameConstants } from "./../../GameConstants";
import { GameVars } from "./../../GameVars";
import { BoardContainer } from "./board-container/BoardContainer";

export class HUD extends Phaser.GameObjects.Container {

    private timer: Phaser.GameObjects.Image;
    private timerContainer: Phaser.GameObjects.Container;

    private highscore: Phaser.GameObjects.Text;
    private score: Phaser.GameObjects.Text;

    private combo: Phaser.GameObjects.Text;

    constructor(scene: Phaser.Scene) {
        
        super(scene);

        if (GameVars.desktop) {
            this.setScale(.82);
        } else {
            this.scaleY = GameVars.scaleY;
        }

        const header = new Phaser.GameObjects.Image(this.scene, GameVars.desktop ? GameVars.gameWidth / 2 + 110 : GameVars.gameWidth / 2 + 53, 0, "texture_atlas_1", "board");
        header.setOrigin(.5, 0);
        this.add(header);

        const number = new Phaser.GameObjects.Image(this.scene, header.x, header.y + 72.5, "texture_atlas_1", "gem_30b");
        this.add(number);

        this.highscore = new Phaser.GameObjects.Text(this.scene, GameVars.desktop ? 470 : 285, 84, GameVars.gameData.highscore.toString(), {fontFamily: "Supercell", fontSize: "30px", color: "#265C80"});
        this.highscore.setOrigin(.5);
        this.highscore.setColor("#00FFC1");
        this.add(this.highscore);

        this.score = new Phaser.GameObjects.Text(this.scene, GameVars.desktop ? 775 : 585, 84, GameVars.score.toString(), {fontFamily: "Supercell", fontSize: "30px", color: "#265C80"});
        this.score.setOrigin(.5);
        this.score.setColor("#00FFC1");
        this.add(this.score);

        this.timerContainer = this.scene.add.container(this.x, this.y);
        if (!GameVars.desktop) {
            this.timerContainer.scaleY = 1.5 * GameVars.scaleY;
            this.timerContainer.scaleX = 1.5;
            this.timerContainer.x = -450;
        }

        let timerBack = new Phaser.GameObjects.Image(this.scene, GameVars.desktop ? GameVars.gameWidth / 2 - 170 : GameVars.gameWidth / 2, 143, "texture_atlas_1", "icon_timer");
        timerBack.scale = .8;

        let timerBar = new Phaser.GameObjects.Image(this.scene, timerBack.x + 190, 143, "texture_atlas_1", "timer_bar_empty");
        timerBar.scale = .8;
        timerBar.setOrigin(.5);

        this.timer = new Phaser.GameObjects.Image(this.scene, timerBack.x + 190, 143, "texture_atlas_1", "timer_bar_full");
        this.timer.scale = .8;
        this.timer.setOrigin(.5);
        this.timerContainer.add(timerBar);
        this.timerContainer.add(this.timer);
        this.timerContainer.add(timerBack);

        this.combo = new Phaser.GameObjects.Text(this.scene, GameVars.desktop ? GameVars.gameWidth / 2 + 110 : GameVars.gameWidth / 2, 0, "COMBO x 1", {fontFamily: "Supercell", fontSize: "70px", color: "#00FFC1"});
        this.combo.visible = false;
        this.combo.setOrigin(.5);
        this.combo.y = (GameVars.gameHeight / 2) / GameVars.scaleY - (GameVars.desktop ? 0 : 120);
        this.add(this.combo);

        if (GameVars.onTutorial) {
            this.timerContainer.visible = false;
        }
    }

    public showTimer(): void {

        this.timerContainer.alpha = 0;
        this.timerContainer.visible = true;

        this.scene.tweens.add({
            targets: this.timerContainer,
            alpha: 1,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 500
        });
    }

    public updateCombo(): void {

        this.combo.alpha = 1;
        this.combo.visible = true;
        this.combo.setText("COMBO x " + GameVars.combo);
        this.combo.setScale(.95 + GameVars.combo * .05);
    }

    public hideCombo(): void {

        this.scene.tweens.add({
            targets: this.combo,
            alpha: 0,
            ease: Phaser.Math.Easing.Linear.Linear,
            duration: 500,
            onComplete: () => {
                this.combo.visible = false;
            },
            onCompleteScope: this
        });
    }

    public update(): void {
        let clip = this.timer.frame.width * (1 - (GameVars.timer / (GameConstants.MAX_TIME + (GameVars.maxValue * 10))));
        clip = Math.min(Math.max(clip, 0.1), this.timer.frame.width);
        this.timer.frame.cutWidth = clip;
        
        this.score.setText(GameVars.score.toString());
        this.highscore.setText(GameVars.gameData.highscore.toString());
    }
}
