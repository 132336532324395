import { GameConstants } from "../../../GameConstants";
import { BoardContainer } from "./BoardContainer";

export class TutorialContainer extends Phaser.GameObjects.Container {

    private topText: Phaser.GameObjects.Text;
    private hand: Phaser.GameObjects.Image;
    private block: Phaser.GameObjects.Image;
    private mark: Phaser.GameObjects.Graphics;

    private timeline: Phaser.Tweens.Timeline;

    constructor(scene: Phaser.Scene) {
        
        super(scene);

        this.topText = new Phaser.GameObjects.Text(this.scene, 0, -220, "Drag and merge the same numbers", {fontFamily: "Supercell", fontSize: "55px", color: "#00FFC1", align: "center"});
        this.topText.setOrigin(.5);
        this.topText.setWordWrapWidth(500);
        this.add(this.topText);

        this.mark = new Phaser.GameObjects.Graphics(this.scene);
        this.mark.setPosition(BoardContainer.OFF_X + 3 * GameConstants.CELL_SIZE, BoardContainer.OFF_Y + 7 * GameConstants.CELL_SIZE)
        this.mark.lineStyle(8, 0xFBDB75);
        this.mark.strokeRoundedRect(-GameConstants.CELL_SIZE_SMALL / 2, -GameConstants.CELL_SIZE_SMALL / 2, GameConstants.CELL_SIZE_SMALL, GameConstants.CELL_SIZE_SMALL, 10);
        this.mark.lineStyle(4, 0x265C80);
        this.mark.strokeRoundedRect(-GameConstants.CELL_SIZE_SMALL / 2 + 4, -GameConstants.CELL_SIZE_SMALL / 2 + 4, GameConstants.CELL_SIZE_SMALL - 8, GameConstants.CELL_SIZE_SMALL - 8, 10);
        this.mark.visible = false;
        this.add(this.mark); 

        this.block = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "gem_2");
        this.block.alpha = .5;
        this.add(this.block);

        this.hand = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "hand_tutorial_2");
        this.hand.setOrigin(0, .2);
        this.add(this.hand);

        this.startPhaseOne();

    }

    public startPhaseOne(): void {

        let xHand = [];
        let yHand = [];

        xHand.push(BoardContainer.OFF_X + 0 * GameConstants.CELL_SIZE);
        yHand.push(BoardContainer.OFF_Y + 7 * GameConstants.CELL_SIZE);

        xHand.push(BoardContainer.OFF_X + 0 * GameConstants.CELL_SIZE);
        yHand.push(BoardContainer.OFF_Y + 5 * GameConstants.CELL_SIZE);

        xHand.push(BoardContainer.OFF_X + 2 * GameConstants.CELL_SIZE);
        yHand.push(BoardContainer.OFF_Y + 5 * GameConstants.CELL_SIZE);

        xHand.push(BoardContainer.OFF_X + 2 * GameConstants.CELL_SIZE);
        yHand.push(BoardContainer.OFF_Y + 7 * GameConstants.CELL_SIZE);

        this.hand.setPosition(xHand[0], yHand[0]);
        this.block.setPosition(xHand[0], yHand[0]);

        this.timeline = this.scene.tweens.timeline({
            targets: [this.hand, this.block],
            loop: -1,
            tweens: [
                {
                    x: xHand[0],
                    y: yHand[0],
                    ease: Phaser.Math.Easing.Cubic.Out,
                    duration: 750,
                    onComplete: () => {
                        // 
                    },
                    onCompleteScope: this
                },
                {
                    x: xHand[1],
                    y: yHand[1],
                    ease: Phaser.Math.Easing.Cubic.Out,
                    duration: 750
                },
                {
                    x: xHand[2],
                    y: yHand[2],
                    ease: Phaser.Math.Easing.Cubic.Out,
                    duration: 750,
                    onComplete: () => {
                        // 
                    },
                    onCompleteScope: this
                },
                {
                    x: xHand[3],
                    y: yHand[3],
                    ease: Phaser.Math.Easing.Cubic.Out,
                    duration: 750,
                    onComplete: () => {
                        this.block.visible = false; 
                        this.hand.setFrame("hand_tutorial");
                    },
                    onCompleteScope: this
                },
                {
                    x: xHand[0],
                    y: yHand[0],
                    ease: Phaser.Math.Easing.Quadratic.In,
                    duration: 750,
                    onComplete: () => {
                        this.block.visible = true; 
                        this.hand.setFrame("hand_tutorial_2");
                    },
                    onCompleteScope: this
                }
                
            ]
        });
    }

    public startPhaseTwo(): void {

        this.topText.setText("Drag off");
        this.topText.setFontSize(40);
        this.block.setFrame("gem_4");
        this.hand.setFrame("hand_tutorial_2");
        this.block.visible = true;
        this.mark.visible = true;

        this.timeline.stop();

        let xHand = [];
        let yHand = [];

        xHand.push(BoardContainer.OFF_X + 5 * GameConstants.CELL_SIZE);
        yHand.push(BoardContainer.OFF_Y + 6 * GameConstants.CELL_SIZE);

        xHand.push(BoardContainer.OFF_X + 5 * GameConstants.CELL_SIZE);
        yHand.push(BoardContainer.OFF_Y + 5 * GameConstants.CELL_SIZE);

        xHand.push(BoardContainer.OFF_X + 3 * GameConstants.CELL_SIZE);
        yHand.push(BoardContainer.OFF_Y + 5 * GameConstants.CELL_SIZE);

        xHand.push(BoardContainer.OFF_X + 3 * GameConstants.CELL_SIZE);
        yHand.push(BoardContainer.OFF_Y + 7 * GameConstants.CELL_SIZE);

        this.hand.setPosition(xHand[0], yHand[0]);
        this.block.setPosition(xHand[0], yHand[0]);

        this.timeline = this.scene.tweens.timeline({
            targets: [this.hand, this.block],
            loop: -1,
            tweens: [
                {
                    x: xHand[0],
                    y: yHand[0],
                    ease: Phaser.Math.Easing.Cubic.Out,
                    duration: 750,
                    onComplete: () => {
                        // 
                    },
                    onCompleteScope: this
                },
                {
                    x: xHand[1],
                    y: yHand[1],
                    ease: Phaser.Math.Easing.Cubic.Out,
                    duration: 750
                },
                {
                    x: xHand[2],
                    y: yHand[2],
                    ease: Phaser.Math.Easing.Cubic.Out,
                    duration: 750,
                    onComplete: () => {
                        // 
                    },
                    onCompleteScope: this
                },
                {
                    x: xHand[3],
                    y: yHand[3],
                    ease: Phaser.Math.Easing.Cubic.Out,
                    duration: 750,
                    onComplete: () => {
                        this.block.visible = false; 
                        this.hand.setFrame("hand_tutorial");
                    },
                    onCompleteScope: this
                },
                {
                    x: xHand[0],
                    y: yHand[0],
                    ease: Phaser.Math.Easing.Quadratic.In,
                    duration: 750,
                    onComplete: () => {
                        this.block.visible = true; 
                        this.hand.setFrame("hand_tutorial_2");
                    },
                    onCompleteScope: this
                }
                
            ]
        });
    }

    public startPhaseThree(): void {

        this.topText.setText("Drag and merge the same numbers");
        this.block.setFrame("gem_3");
        this.hand.setFrame("hand_tutorial_2");
        this.block.visible = true;
        this.mark.visible = false;

        this.timeline.stop();

        let xHand = [];
        let yHand = [];

        xHand.push(BoardContainer.OFF_X + 5 * GameConstants.CELL_SIZE);
        yHand.push(BoardContainer.OFF_Y + 7 * GameConstants.CELL_SIZE);

        xHand.push(BoardContainer.OFF_X + 5 * GameConstants.CELL_SIZE);
        yHand.push(BoardContainer.OFF_Y + 5 * GameConstants.CELL_SIZE);

        xHand.push(BoardContainer.OFF_X + 2 * GameConstants.CELL_SIZE);
        yHand.push(BoardContainer.OFF_Y + 5 * GameConstants.CELL_SIZE);

        xHand.push(BoardContainer.OFF_X + 2 * GameConstants.CELL_SIZE);
        yHand.push(BoardContainer.OFF_Y + 7 * GameConstants.CELL_SIZE);

        this.hand.setPosition(xHand[0], yHand[0]);
        this.block.setPosition(xHand[0], yHand[0]);

        this.timeline = this.scene.tweens.timeline({
            targets: [this.hand, this.block],
            loop: -1,
            tweens: [
                {
                    x: xHand[0],
                    y: yHand[0],
                    ease: Phaser.Math.Easing.Cubic.Out,
                    duration: 750,
                    onComplete: () => {
                        // 
                    },
                    onCompleteScope: this
                },
                {
                    x: xHand[1],
                    y: yHand[1],
                    ease: Phaser.Math.Easing.Cubic.Out,
                    duration: 750
                },
                {
                    x: xHand[2],
                    y: yHand[2],
                    ease: Phaser.Math.Easing.Cubic.Out,
                    duration: 750,
                    onComplete: () => {
                        // 
                    },
                    onCompleteScope: this
                },
                {
                    x: xHand[3],
                    y: yHand[3],
                    ease: Phaser.Math.Easing.Cubic.Out,
                    duration: 750,
                    onComplete: () => {
                        this.block.visible = false; 
                        this.hand.setFrame("hand_tutorial");
                    },
                    onCompleteScope: this
                },
                {
                    x: xHand[0],
                    y: yHand[0],
                    ease: Phaser.Math.Easing.Quadratic.In,
                    duration: 750,
                    onComplete: () => {
                        this.block.visible = true; 
                        this.hand.setFrame("hand_tutorial_2");
                    },
                    onCompleteScope: this
                }
                
            ]
        });
    }

    public startPhaseFour(): void {

        this.block.setFrame("gem_4");
        this.hand.setFrame("hand_tutorial_2");
        this.block.visible = true;

        this.timeline.stop();

        let xHand = [];
        let yHand = [];

        xHand.push(BoardContainer.OFF_X + 2 * GameConstants.CELL_SIZE);
        yHand.push(BoardContainer.OFF_Y + 7 * GameConstants.CELL_SIZE);

        xHand.push(BoardContainer.OFF_X + 3 * GameConstants.CELL_SIZE);
        yHand.push(BoardContainer.OFF_Y + 7 * GameConstants.CELL_SIZE);

        this.hand.setPosition(xHand[0], yHand[0]);
        this.block.setPosition(xHand[0], yHand[0]);

        this.timeline = this.scene.tweens.timeline({
            targets: [this.hand, this.block],
            loop: -1,
            tweens: [
                {
                    x: xHand[0],
                    y: yHand[0],
                    ease: Phaser.Math.Easing.Cubic.Out,
                    duration: 750,
                    onComplete: () => {
                        // 
                    },
                    onCompleteScope: this
                },
                {
                    x: xHand[1],
                    y: yHand[1],
                    ease: Phaser.Math.Easing.Cubic.Out,
                    duration: 750,
                    onComplete: () => {
                        this.block.visible = false; 
                        this.hand.setFrame("hand_tutorial");
                    },
                    onCompleteScope: this
                },
                {
                    x: xHand[0],
                    y: yHand[0],
                    ease: Phaser.Math.Easing.Quadratic.In,
                    duration: 750,
                    onComplete: () => {
                        this.block.visible = true; 
                        this.hand.setFrame("hand_tutorial_2");
                    },
                    onCompleteScope: this
                }
                
            ]
        });
    }

    public startPhaseFive(): void {

        this.block.setFrame("gem_5");
        this.hand.setFrame("hand_tutorial_2");
        this.block.visible = true;

        this.scene.tweens.add({
            targets: this.topText,
            alpha: 0,
            ease: Phaser.Math.Easing.Linear.Linear,
            duration: 500
        });

        this.timeline.stop();

        let xHand = [];
        let yHand = [];

        xHand.push(BoardContainer.OFF_X + 1 * GameConstants.CELL_SIZE);
        yHand.push(BoardContainer.OFF_Y + 7 * GameConstants.CELL_SIZE);

        xHand.push(BoardContainer.OFF_X + 3 * GameConstants.CELL_SIZE);
        yHand.push(BoardContainer.OFF_Y + 7 * GameConstants.CELL_SIZE);

        this.hand.setPosition(xHand[0], yHand[0]);
        this.block.setPosition(xHand[0], yHand[0]);

        this.timeline = this.scene.tweens.timeline({
            targets: [this.hand, this.block],
            loop: -1,
            tweens: [
                {
                    x: xHand[0],
                    y: yHand[0],
                    ease: Phaser.Math.Easing.Cubic.Out,
                    duration: 750,
                    onComplete: () => {
                        // 
                    },
                    onCompleteScope: this
                },
                {
                    x: xHand[1],
                    y: yHand[1],
                    ease: Phaser.Math.Easing.Cubic.Out,
                    duration: 750,
                    onComplete: () => {
                        this.block.visible = false; 
                        this.hand.setFrame("hand_tutorial");
                    },
                    onCompleteScope: this
                },
                {
                    x: xHand[0],
                    y: yHand[0],
                    ease: Phaser.Math.Easing.Quadratic.In,
                    duration: 750,
                    onComplete: () => {
                        this.block.visible = true; 
                        this.hand.setFrame("hand_tutorial_2");
                    },
                    onCompleteScope: this
                }
                
            ]
        });
    }

    public startPhaseSix(): void {

        this.topText.setText("");
        this.block.setFrame("gem_6");
        this.hand.setFrame("hand_tutorial_2");
        this.block.visible = true;

        this.timeline.stop();

        let xHand = [];
        let yHand = [];

        xHand.push(BoardContainer.OFF_X + 3 * GameConstants.CELL_SIZE);
        yHand.push(BoardContainer.OFF_Y + 7 * GameConstants.CELL_SIZE);

        xHand.push(BoardContainer.OFF_X + 4 * GameConstants.CELL_SIZE);
        yHand.push(BoardContainer.OFF_Y + 7 * GameConstants.CELL_SIZE);

        this.hand.setPosition(xHand[0], yHand[0]);
        this.block.setPosition(xHand[0], yHand[0]);

        this.timeline = this.scene.tweens.timeline({
            targets: [this.hand, this.block],
            loop: -1,
            tweens: [
                {
                    x: xHand[0],
                    y: yHand[0],
                    ease: Phaser.Math.Easing.Cubic.Out,
                    duration: 750,
                    onComplete: () => {
                        // 
                    },
                    onCompleteScope: this
                },
                {
                    x: xHand[1],
                    y: yHand[1],
                    ease: Phaser.Math.Easing.Cubic.Out,
                    duration: 750,
                    onComplete: () => {
                        this.block.visible = false; 
                        this.hand.setFrame("hand_tutorial");
                    },
                    onCompleteScope: this
                },
                {
                    x: xHand[0],
                    y: yHand[0],
                    ease: Phaser.Math.Easing.Quadratic.In,
                    duration: 750,
                    onComplete: () => {
                        this.block.visible = true; 
                        this.hand.setFrame("hand_tutorial_2");
                    },
                    onCompleteScope: this
                }
                
            ]
        });
    }
}
