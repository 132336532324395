import { EndTutorialLayer } from "./EndTutorialLayer";
import { WinLayer } from "./WinLayer";
import { GameOverLayer } from "./GameOverLayer";
import { GameVars } from "./../../GameVars";
import { GameManager } from "../../GameManager";
import { HUD } from "./HUD";
import { GUI } from "./GUI";
import { BoardManager } from "./BoardManager";
import { BoardContainer } from "./board-container/BoardContainer";
import { MenuLayer } from "./MenuLayer";
import { BootScene } from "../BootScene";
import { AudioManager } from "../AudioManager";

export class BoardScene extends Phaser.Scene {

    public static currentInstance: BoardScene;

    public gui: GUI;
    
    private hud: HUD;
    private boardContainer: BoardContainer;
    private menuLayer: MenuLayer;
    private gameOverLayer: GameOverLayer;
    private winLayer: WinLayer;

    private spacebar: Phaser.Input.Keyboard.Key;
    
    constructor() {

        super("BoardScene");
        
        BoardScene.currentInstance = this;
    }

    public create(): void {

        BoardManager.init();

        GameManager.setCurrentScene(this);

        this.boardContainer = new BoardContainer(this);
        this.add.existing(this.boardContainer);

        this.hud = new HUD(this);
        this.add.existing(this.hud);

        this.gui = new GUI(this);
        this.add.existing(this.gui);

        this.menuLayer = new MenuLayer(this);
        this.add.existing(this.menuLayer);

        this.winLayer = new WinLayer(this);
        this.add.existing(this.winLayer);

        this.cameras.main.fadeIn(300, 0, 0, 0);

        if (GameVars.desktop) {
            this.spacebar = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.SPACE);
        } else {
            this.input.on("pointerdown", () => {

                if (!this.game.scale.isFullscreen) {
                    this.game.scale.startFullscreen();
                    BootScene.onOrientationChange();
                }
            }, this);
        }

        AudioManager.setVolume(0.6);
    }

    public update(): void {

        if (GameVars.desktop) {
            if (Phaser.Input.Keyboard.JustDown(this.spacebar)) {

                if (GameVars.paused) {
                    BoardManager.hideMenu();
                } else {
                    BoardManager.showMenu();
                }
            }
        }
        
        this.boardContainer.update();


        if (GameVars.paused) {
            return;
        }

        this.hud.update();
        BoardManager.update();
    }

    public showMenu(): void {

        AudioManager.setVolume(0.3);
        this.menuLayer.visible = true;
    }

    public hideMenu(): void {
        
        AudioManager.setVolume(0.6);
        this.menuLayer.visible =  false;
    }

    public showGameOver(): void {

        GameVars.simplio.SetScore(GameVars.simplioUserId, GameVars.score).then(() => {
            console.warn("Score updated");
        });

        this.gui.hideMenu();
        this.gameOverLayer = new GameOverLayer(this);
        this.add.existing(this.gameOverLayer);
    }

    public showWin(): void {

        GameVars.simplio.SetScore(GameVars.simplioUserId, GameVars.score).then(() => {
            console.warn("Score updated");
        });

        this.winLayer.show();
    }

    public showEndTutorial(): void {

        this.boardContainer.showEndTutorial();

        let endTutorialLayer = new EndTutorialLayer(this);
        this.add.existing(endTutorialLayer);
    }

    public tutorialEnd(): void {

        this.hud.showTimer();
        this.gui.showMenu();
    }

    public updateCombo(): void {

        this.hud.updateCombo();
    }

    public hideCombo(): void {

        this.hud.hideCombo();
    }
}
