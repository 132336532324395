import { BoardManager } from "./BoardManager";
import { GameVars } from "./../../GameVars";
import { BackgroundMenu } from "./BackgroundMenu";

export class EndTutorialLayer extends Phaser.GameObjects.Container {

    constructor(scene: Phaser.Scene) {

        super(scene);

        const bck = new Phaser.GameObjects.Image(this.scene, 0, 0, "gradient_bck");
        bck.alpha = 0.5;
        bck.setTint(0x1f2e3c);
        bck.setOrigin(0);
        bck.setScale(GameVars.gameWidth / 16, GameVars.gameHeight / 256);
        bck.setInteractive();
        bck.on("pointerdown", () => {
            BoardManager.tutorialEnd();
            this.destroy();
        }, this);
        this.add(bck);

        const midContainer = new Phaser.GameObjects.Container(this.scene);
        midContainer.setPosition(GameVars.gameWidth / 2, GameVars.gameHeight / 2 + 80);
        midContainer.scaleY = GameVars.scaleY;
        this.add(midContainer);

        const topText = new Phaser.GameObjects.Text(this.scene, 0, -150, "Can you merge to", {fontFamily: "Supercell", fontSize: "30px", color: "#00FFC1", align: "center"});
        topText.setOrigin(.5);
        midContainer.add(topText);

        const block = new Phaser.GameObjects.Image(this.scene, -40, -30, "texture_atlas_1", "gem_30b");
        block.setScale(1.5);
        midContainer.add(block);

        const frame = new Phaser.GameObjects.Image(this.scene, -40, -30, "texture_atlas_1", "golden_frame");
        frame.setScale(1.5);
        midContainer.add(frame);

        const questionMark = new Phaser.GameObjects.Text(this.scene, 70, -40, "?", {fontFamily: "Supercell", fontSize: "130px", color: "#00FFC1", align: "center"});
        questionMark.setOrigin(.5);
        midContainer.add(questionMark);

        const continueText = new Phaser.GameObjects.Text(this.scene, 0, 160, "Tap to continue", {fontFamily: "Supercell", fontSize: "30px", color: "#00FFC1", align: "center"});
        continueText.setOrigin(.5);
        midContainer.add(continueText);

        this.scene.tweens.add({
            targets: continueText,
            scaleX: 1.05,
            scaleY: 1.05,
            ease: Phaser.Math.Easing.Linear.Linear,
            duration: 600,
            yoyo: true,
            repeat: -1
        });

        this.alpha = 0;

        this.scene.tweens.add({
            targets: this,
            alpha: 1,
            ease: Phaser.Math.Easing.Linear.Linear,
            duration: 500
        });
    }
}
